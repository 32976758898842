import React, { useState, useEffect } from "react";
import { Avatar, Typography } from "@mui/material";
import FormikController from "../../../components/FormikController/FormikController";
import { CareGiverURL } from "../../../Url/Url";
import AgencyStore from "../../../Store/AgencyStore";
import axios from "axios";
import { useParams } from "react-router-dom";
import CareGiverStore from "../../../Store/CareGiverStore";
import Icon from "../../../components/Icons/Icon";
import { observer } from "mobx-react";

const EmpSignature = observer(() => {
  const { employeeId } = useParams();
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const signatureLink = CareGiverStore.empSignatureAllData?.signatureLink;
    if (signatureLink) {
      setImage(signatureLink);
    }
  }, [CareGiverStore.empSignatureAllData?.signatureLink]);

  const handleImageUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const validFormats = ["image/png", "image/jpeg", "image/jpg"];
      if (validFormats.includes(selectedFile.type)) {
        setImage(URL.createObjectURL(selectedFile));
        setFile(selectedFile);
      } else {
        AgencyStore.showAlert(
          "error",
          "Invalid File Format. Please upload only jpeg, jpg, or png file."
        );
      }
    }
  };

  const addEmpSignature = async () => {
    if (!file) {
      AgencyStore.showAlert(
        "error",
        "Please upload a signature file before submitting."
      );
      return;
    }

    AgencyStore.setIsLoading(true);

    const formData = new FormData();
    formData.append("empProfileId", employeeId);
    formData.append("empSignFile", file);

    await axios
      .post(CareGiverURL.ADD_EMP_SIGNATURE, formData, {
        headers: {
          Authorization: localStorage.getItem("loginToken"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          CareGiverStore.getEmpSignature(employeeId);

          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Successfully added");
        } else if (res?.data?.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to add Signature");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        if (err.response && err.response.status === 422) {
          const errorFields = err.response.data.fields || [];
          errorFields.forEach((field) => {
            AgencyStore.showAlert("error", field.description);
          });
        } else {
          AgencyStore.showAlert("error", "Failed to add Signature");
        }
      });
  };

  return (
    <div className="listMainDivContainer signatureMain">
      <div className="signatureFormContainer">
        <div className="signatureTitleBtn">
          <div className="signatureIconTitle">
            <div className="signatureIconCircle">
              <Icon iconName="signature" className="profileEeachMenuIcon" />
            </div>
            <div className="signatureTitle">Signature</div>
          </div>
          <FormikController
            name="Upload Signature"
            control="buttons"
            className="buttonSelectedGreen fixedWidth"
            fullWidth="false"
            onClick={addEmpSignature}
            disabled={!image}
          />
        </div>
        <div className="subSignatureFormContainer">
          <div
            className="signatureContainer"
            onClick={() =>
              document.getElementById("upload-photo-input").click()
            }
          >
            {image ? (
              <Avatar
                src={image}
                alt="Caregiver Signature"
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 0,
                }}
              />
            ) : (
              <Typography variant="body1" className="signatureLabel">
                Upload Signature
              </Typography>
            )}
            <input
              accept="image/png, image/jpeg, image/jpg"
              style={{
                display: "none",
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                cursor: "pointer",
                opacity: 0,
              }}
              id="upload-photo-input"
              type="file"
              onChange={handleImageUpload}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(EmpSignature);

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AgencyStore from "../../../Store/AgencyStore";
import CareGiverStore from "../../../Store/CareGiverStore";
import { useParams } from "react-router-dom";
import FormikController from "../../../components/FormikController/FormikController";
import { useFormik } from "formik";
import * as Yup from "yup";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";
import axios from "axios";
import { CareGiverURL, ClientURL, getVisitTypeName } from "../../../Url/Url";
import ClientStore from "../../../Store/ClientStore";
import Icon from "../../../components/Icons/Icon";

const ClientDocViewerList = observer(({ id, DocType }) => {
  const employeeId = id ? id : useParams()?.employeeId;
  const { clientId } = useParams();

  CareGiverStore.setSelectedEmloyeeId(employeeId);
  const downloadPDF = (data, fileName) => {
    AgencyStore.downloadFile(data, fileName);
  };
  const openPdf = (data) => {
    AgencyStore.openPDF(data);
  };

  const formik = useFormik({
    initialValues: {
      uniqueCode: "",
    },

    validationSchema: Yup.object({
      uniqueCode: Yup.string().required("Unique Code Required"),
    }),
    onSubmit: (values) => {
      if (ClientStore?.ClientAllData?.client?.mra === values?.uniqueCode) {
        CareGiverStore.setIsUniqueCodeEntered(true);
      } else {
        CareGiverStore.setIsUniqueCodeEntered(false);
        AgencyStore.showAlert("error", "Invalid Unique code");
      }

      // if (DocType == "nursingAssessmentDoc") {
      //   if (ClientStore?.ClientAllData?.client?.mra === values?.uniqueCode) {
      //     CareGiverStore.setIsUniqueCodeEntered(true);
      //   } else {
      //     CareGiverStore.setIsUniqueCodeEntered(false);
      //     AgencyStore.showAlert("error", "Invalid Unique code");
      //   }
      // } else {
      //   if (
      //     CareGiverStore.careGiverProfileData.employeeId === values.uniqueCode
      //   ) {
      //     CareGiverStore.setIsUniqueCodeEntered(true);
      //   } else {
      //     CareGiverStore.setIsUniqueCodeEntered(false);
      //     AgencyStore.showAlert("error", "Invalid Unique code");
      //   }
      // }
    },
  });

  const addNewFile = (data) => {
    ClientStore.setFileUploadPopUp(true);

    CareGiverStore.setSelectedDoc(data);
  };

  const deleteNursingAssessmentDoc = (doc) => {
    AgencyStore.setIsLoading(true);
    const params = { clientId: clientId, naDocId: doc.id };
    axios
      .post(ClientURL.DELETE_NURSINGASSESSMENT_DOCUMENT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          if (DocType === "nursingAssessmentDoc") {
            ClientStore.getNursingAssessmentDocuments(
              clientId,
              "NURSING_ASSESSMENT"
            );
          } else if (DocType === "MEDICATION_RECORDS_LIST") {
            ClientStore.getNursingAssessmentDocuments(
              clientId,
              "MEDICATION_RECORDS_LIST"
            );
          } else if (DocType === "HOME_SAFETY") {
            ClientStore.getNursingAssessmentDocuments(clientId, "HOME_SAFETY");
          } else if (DocType === "CLIENT_CARE_PLAN") {
            ClientStore.getNursingAssessmentDocuments(
              clientId,
              "CLIENT_CARE_PLAN"
            );
          } else if (DocType === "CAREGIVER_EVALUATION_FORM") {
            ClientStore.getNursingAssessmentDocuments(
              clientId,
              "CAREGIVER_EVALUATION_FORM"
            );
          } else if (DocType === "CAREGIVER_PLAN_OF_SERVICE") {
            ClientStore.getNursingAssessmentDocuments(
              clientId,
              "CAREGIVER_PLAN_OF_SERVICE"
            );
          } else if (DocType === "COMPETENCY_CHECKLIST_PERSONAL_CARE_AIDE") {
            ClientStore.getNursingAssessmentDocuments(
              clientId,
              "COMPETENCY_CHECKLIST_PERSONAL_CARE_AIDE"
            );
          } else if (
            DocType === "PARTICIPANT_REVIEW_AND_CAREGIVER_SUPERVISORY_VISIT"
          ) {
            ClientStore.getNursingAssessmentDocuments(
              clientId,
              "PARTICIPANT_REVIEW_AND_CAREGIVER_SUPERVISORY_VISIT"
            );
          }
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "success",
            "The document has been successfully deleted."
          );
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to delete the document.");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to delete the document.");
      });
  };

  const [responseData, setResponseData] = useState({});
  useEffect(() => {
    if (DocType == "nursingAssessmentDoc") {
      setResponseData(ClientStore?.clientAllNursingAssessmentDocs?.resultMap);
    } else if (DocType == "MEDICATION_RECORDS_LIST") {
      setResponseData(ClientStore?.clientAllMedicationRecordsDocs?.resultMap);
    } else if (DocType == "HOME_SAFETY") {
      setResponseData(ClientStore?.clientAllHomeSafetyDocs?.resultMap);
    } else if (DocType == "CLIENT_CARE_PLAN") {
      setResponseData(ClientStore?.clientAllClientCarePlanDocs?.resultMap);
    } else if (DocType == "CAREGIVER_EVALUATION_FORM") {
      setResponseData(
        ClientStore?.clientAllCareGiverEvaluationFormDocs?.resultMap
      );
    } else if (DocType == "CAREGIVER_PLAN_OF_SERVICE") {
      setResponseData(
        ClientStore?.clientAllCareGiverPlanOfServiceDocs?.resultMap
      );
    } else if (DocType == "COMPETENCY_CHECKLIST_PERSONAL_CARE_AIDE") {
      setResponseData(
        ClientStore?.clientAllCompetencyChecklistPersonalCareAideDocs?.resultMap
      );
    } else if (
      DocType == "PARTICIPANT_REVIEW_AND_CAREGIVER_SUPERVISORY_VISIT"
    ) {
      setResponseData(
        ClientStore?.clientAllParticipantReviewAndCareGiverSupervisoryVisitDocs
          ?.resultMap
      );
    }
  }, [
    ClientStore?.clientAllNursingAssessmentDocs,
    ClientStore?.clientAllMedicationRecordsDocs,
    ClientStore?.clientAllHomeSafetyDocs,
    ClientStore?.clientAllClientCarePlanDocs,
    ClientStore?.clientAllCareGiverEvaluationFormDocs,
    ClientStore?.clientAllCareGiverPlanOfServiceDocs,
    ClientStore?.clientAllCompetencyChecklistPersonalCareAideDocs,
    ClientStore?.clientAllParticipantReviewAndCareGiverSupervisoryVisitDocs,
  ]);

  return (
    <div className="clientDocviewMainContainer">
      {/* {CareGiverStore.isUniqueCodeEntered === false ? (
        <div className="listMainDivContainer enterCodeContainer">
          <div>
            <FormikController
              name="uniqueCode"
              error
              label="Unique Code"
              type="text"
              control="textfield"
              {...formik.getFieldProps("uniqueCode")}
              touch={formik.touched.uniqueCode}
              errorname={formik.errors.uniqueCode}
            />
          </div>
          <FormikController
            name="Submit code"
            control="buttons"
            onClick={formik.handleSubmit}
            className="buttonSelected fixedWidth"
            fullWidth="false"
          />
        </div>
      ) : ( */}
      <div className="listMainDivContainer">
        {Object.entries(responseData).map(([key1, document1]) => (
          <div key={key1} className="docDetailsMainContainer">
            <div className="docTypeTitle">
              <div>{key1}</div>
              <div
                className="docAddContainer"
                onClick={() => {
                  addNewFile(key1);
                }}
              >
                <AiOutlinePlusCircle className="docAddIcon" /> Add New
              </div>
            </div>
            {Object.entries(document1).map(([key2, document2]) => (
              <div className="docSubTypeTitle">
                <div className="docTypeTitle">{getVisitTypeName(key2)} </div>
                <div className="docDetailsMainContainer">
                  {document2.map((document) => (
                    <div key={document.id} className="docDetailsContainer">
                      <div className="docDetailsLeftContainer">
                        <div className="docIconContainer">
                          {" "}
                          <Icon iconName="pdf" />
                        </div>
                        <div>
                          <div className="docTitle">{document.fileName}</div>
                          <div className="docSubTitlemainContainer">
                            {document.fileUploadDateManual && (
                              <div className="dovViewerDateContainer">
                                Visit Date: {document.fileUploadDateManual}
                              </div>
                            )}

                            <ConfirmPopUp
                              title="Delete"
                              content="You are about to perform a critical action that cannot be undone. Please confirm your decision below."
                              onConfirm={() => {
                                deleteNursingAssessmentDoc(document);
                              }}
                              clickHTML={
                                <div className="boderRoundedButton">
                                  <Icon
                                    iconName="delete"
                                    className="boderRoundedButtonIconRed"
                                  />
                                  <spam className="boderRoundedButtonText">
                                    Delete
                                  </spam>
                                </div>
                              }
                            />

                            <div
                              className="boderRoundedButton"
                              onClick={() => {
                                downloadPDF(
                                  document.filePath,
                                  document.fileName
                                );
                              }}
                            >
                              <Icon
                                iconName="pdf2"
                                className="boderRoundedButtonIcon"
                              />
                              <span className="boderRoundedButtonText">
                                Download
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="boderRoundedButton"
                        onClick={() => {
                          openPdf(document.filePath);
                        }}
                      >
                        <Icon
                          iconName="view"
                          className="boderRoundedButtonIconGreen"
                        />

                        <span className="boderRoundedButtonText">View</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {/* )} */}
    </div>
  );
});

export default ClientDocViewerList;

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "../../../CSS/Profile.css";

import GlobelStore from "../../../Store/GlobelStore";

import ClientStore from "../../../Store/ClientStore";

import PageTitle from "../../PageTitle";

import CareGiverMainPage from "./CareGiverMainPage";
import AdminEmployeeMainPage from "./AdminEmployeeMainPage";
import CommunityOutReachCoordinatorMainPage from "./CommunityOutReachCoordinatorMainPage";
import ClientMainPage from "./ClientMainPage";
import NurseMainPage from "./NurseMainPage";
import Icon from "../../../components/Icons/Icon";

const ManualNotificationViewPage = observer(() => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    GlobelStore.getEmployeeList("CareGiver");
    GlobelStore.getEmployeeList("AdminEmployee");
    GlobelStore.getEmployeeList("CommunityCoordinator");
    GlobelStore.getEmployeeList("RN");
    ClientStore.getClientList();
    GlobelStore.getAvailableRoles();

    // NotificationStore.getEmpManualNoti();
    // NotificationStore.getClientManualNoti();
  }, []);

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle="Send Email/SMS"
          subHeaderTitle=" Navigate/Send Email/SMS"
        />
      </div>
      <div className="profileMainContainer">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              className="profileTabsMenu"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="careGiver"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Personal Care Aid"
                  value="1"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="admin" className="profileEeachMenuIcon" />
                  }
                  label="Admin Employee"
                  value="2"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="coordinator"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Community Outreach coordinator"
                  value="3"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="client" className="profileEeachMenuIcon" />
                  }
                  label="Client"
                  value="4"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="nurse" className="profileEeachMenuIcon" />
                  }
                  label="Nurse"
                  value="5"
                />
              </TabList>
            </Box>

            <TabPanel className="profileEachTabPanel" value="1">
              <CareGiverMainPage />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="2">
              <AdminEmployeeMainPage />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="3">
              <CommunityOutReachCoordinatorMainPage />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="4">
              <ClientMainPage />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="5">
              <NurseMainPage />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
});

export default ManualNotificationViewPage;

import React from "react";
import "../../../CSS/NursingAssessment.css";
import { handleGeneratePdf } from "../../../Utils/Utils";
import FormikController from "../../../components/FormikController/FormikController";
import { TextField } from "@mui/material";

const NursingAssesmentDoc = () => {
  const handlePdfGeneration = (openInNewWindow) => {
    // handleGeneratePdf({
    //   contentElement: document.getElementById("content-id"),
    //   filename: "client care plan.pdf",
    //   headerText: "",
    //   headerAlign: "center",
    //   textAlign: "left",
    //   imageData: "Image Data",
    //   columns: null,
    //   rows: null,
    //   openInNewWindow: openInNewWindow,
    // });
  };

  const [state, setState] = React.useState(false);
  const handleSave = () => {
    setState(true);
  };

  return (
    <>
      {/* <button onClick={handlePdfGeneration}>Generate PDF</button> */}
      {state ? (
        <>
          <div className="nursingAssessmentButtons">
            <FormikController
              control="buttons"
              name="Edit"
              variant="none"
              fullWidth="false"
              // onClick={handlePdfGeneration}
              // startIcon={<IoIosAddCircleOutline />}
            />
            <FormikController
              control="buttons"
              name="Preview"
              variant="none"
              fullWidth="false"
              onClick={() => {
                handlePdfGeneration(true);
              }}
              // startIcon={<IoIosAddCircleOutline />}
            />
            <FormikController
              control="buttons"
              name="Download"
              variant="none"
              fullWidth="false"
              onClick={() => handlePdfGeneration(false)}
              // startIcon={<IoIosAddCircleOutline />}
            />
          </div>
        </>
      ) : (
        <div className="nursingAssessmentButtonContainer">
          <FormikController
            control="buttons"
            name="Save"
            variant="none"
            onClick={handleSave}
            // startIcon={<IoIosAddCircleOutline />}
          />
        </div>
      )}

      <div className="nursingAssessmentContainer">
        <div id="content-id">
          <div className="nursingAssessmentSection">
            <div className="nursingHeading">NURSING ASSESSMENT*</div>
            <div className="contactInfo">
              <div className="inputWrapper">
                <div className="inputContainer">
                  <div>Client Name:</div>
                  <div>
                    <input type="text" className="infoInputSection" />
                  </div>
                </div>
                <div className="inputContainer">
                  <div>DOB:</div>
                  <div>
                    <input type="date" className="infoInputSection" />
                  </div>
                </div>
              </div>

              <div className="inputWrapper">
                <div className="inputContainer">
                  <div>Personal Care Aide Phone:</div>
                  <div>
                    <input type="text" className="infoInputSection" />
                  </div>
                </div>
              </div>

              <div className="inputWrapper">
                <div className="inputContainer">
                  <div>Client Address:</div>
                  <div>
                    <input type="text" className="infoInputSection" />
                  </div>
                </div>
              </div>

              <div className="inputWrapper">
                <div className="inputContainer">
                  <div>Doctor's Name:</div>
                  <div>
                    <input type="text" className="infoInputSection" />
                  </div>
                </div>
                <div className="inputContainer">
                  <div>Doctor's Phone:</div>
                  <div>
                    <input type="text" className="infoInputSection" />
                  </div>
                </div>
              </div>

              <div className="inputWrapper">
                <div className="inputContainer">
                  <div>Contact Person:</div>
                  <div>
                    <input type="text" className="infoInputSection" />
                  </div>
                </div>
                <div className="inputContainer">
                  <div>Contact's Phone:</div>
                  <div>
                    <input type="text" className="infoInputSection" />
                  </div>
                </div>
              </div>
            </div>

            <div className="tableContainer">
              <table className="tableWrapper">
                {" "}
                <tr className="tableHeader">
                  <th>NURSING ASSESSMENT</th>
                </tr>
                <tr className="subTableHeaderContainer">
                  <td className="NAsubTableHeaderLabel">General Topics</td>
                  <td className="NAsubTableHeaderLabel NAmiddle ">
                    Subject Matter
                  </td>
                  <td className="NAsubTableHeaderLabel">Action(S) Indicated</td>
                </tr>
                <tr className="NAtitleforCategory">
                  <td>Medical Information</td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Medical Conditions</td>
                  <td className="NAmiddle">
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                    />
                  </td>
                  <td className="NALeft">
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      multiline
                      rows={2}
                      variant="standard"
                    />
                  </td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Medical Background</td>
                  <td className="NAmiddle">
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                    />
                  </td>
                  <td className="NALeft">
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      multiline
                      rows={2}
                      variant="standard"
                    />
                  </td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">
                    Hospitalization/Office Visits
                  </td>
                  <td className="NAmiddle">
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                    />
                  </td>
                  <td className="NALeft">
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      multiline
                      rows={4}
                      variant="standard"
                    />
                  </td>
                </tr>
                <tr className="NAtitleforCategory">
                  <td>SYSTEMS ASSESSMENT</td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Vital Signs</td>
                  <td className="systemAssessmiddle">
                    <div className="systemAssessContent">
                      <label>Temperature:</label>
                      <div className="checkboxContainer">
                        <label>Pulse:</label>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Apical
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Radial
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          RT
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          LT
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Regular
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Irregular
                        </div>
                      </div>
                      <label>Respirations:</label>
                      <div className="checkboxContainer">
                        <label>B/P:</label>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          RT
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          LT
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Sitting
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Standing
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Lying
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Neurological</td>
                  <td className="systemAssessmiddle">
                    <div className="systemAssessContent">
                      <label>Orientation:</label>
                      <label>Note:</label>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Alert
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Oriented
                        </div>
                      </div>

                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Disoriented
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Confused
                        </div>
                      </div>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Forgetful:
                        </div>
                      </div>

                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          No verbal response
                        </div>

                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          No response to pain
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Sedated but arousal
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Aphasic
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Dysphasic
                        </div>
                      </div>
                      <label>Pupils</label>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Equal/Reacts to light
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Other:
                          <input type="text" />
                        </div>
                      </div>

                      <label>Grip</label>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Strong bilaterally
                        </div>
                      </div>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Weak
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Arm
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Rt
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Lt
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Leg
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Rt
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Lt
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Integumentary</td>
                  <td className="systemAssessmiddle">
                    <div className="systemAssessContent">
                      <label>Skin:</label>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Warm
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Cool
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Dry
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Clammy
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Turgor
                        </div>
                      </div>

                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          pink
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          pale
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Other
                        </div>
                      </div>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Rash
                        </div>
                      </div>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Itching
                        </div>
                      </div>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Brushing
                        </div>
                      </div>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Inclusion
                        </div>
                      </div>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Wound
                        </div>
                      </div>

                      <div className="checkboxContainer">
                        <lable>Oral membranes:</lable>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Pink
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Moist
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Dry/Cracked
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Mouth ulcers:
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">CardioVascular</td>
                  <td className="systemAssessmiddle">
                    <div className="systemAssessContent">
                      <label>Peripheral:</label>

                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          WLN
                        </div>
                      </div>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Sensation:
                        </div>
                      </div>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Paresthesia:
                        </div>
                      </div>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Capillary refill[&gt;, &lt; 3-5sec]:
                        </div>
                      </div>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Cyanosis:
                        </div>
                      </div>
                    </div>

                    <div className="systemAssessContent">
                      <label>Edema</label>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          None
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Pitting
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          1+
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          2+
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          3+
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Non Pitting
                        </div>
                      </div>
                      <span>Location of edema:</span>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Chest pain :
                        </div>
                      </div>

                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          AV shunt
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Bruit
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Thrill
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Respiratory</td>
                  <td className="systemAssessmiddle">
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        WLN
                      </div>
                    </div>

                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Labored
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        SOB
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Dyspnea while
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Sitting
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Exertion:
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Wheezing:
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Rhonchi:
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Rales:
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Productive cough: color of sputum:
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Non-productive cough
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Moist cough
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Dry cough
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Oxygen: N/A
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Musculoskeletal</td>
                  <td className="systemAssessmiddle">
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        WLN
                      </div>
                    </div>
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Weakness:
                      </div>
                    </div>
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Pain:
                      </div>
                    </div>
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Stiffness:
                      </div>
                    </div>
                    <span>Giat</span>
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Steady
                      </div>
                    </div>
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Unsteady:
                      </div>
                    </div>
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Contractures:
                      </div>
                    </div>
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Amputation:
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Gastrointestinal</td>
                  <td className="systemAssessmiddle">
                    <span> Abdomen:</span>
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Soft
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Firm
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        DisIntended
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Tender
                      </div>
                    </div>
                    <span>Bowels sounds</span>
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Present all quads
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Absent
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Hyperactive
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Hypoactive.
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Ostomy:
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Constipation:
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Diarrhea
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Rectal Bleeding
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Incontinent of stool.
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Nausea
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Vomiting
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Pain:
                      </div>
                    </div>
                    <span>Last BM:</span>
                  </td>
                  <td className="NALeft"></td>

                  <td className="NAresultLeftTitle">Genitourinary</td>
                  <td className="systemAssessmiddle">
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        WLN
                      </div>
                    </div>
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Dysuria
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Pain
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Hematuria
                      </div>
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Odor
                      </div>
                    </div>
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        <input type="checkbox" />
                        Incontinent
                      </div>
                    </div>
                    <div className="checkboxContainer">
                      <div className="innerCheckContainer">
                        Foley :size <input type="text" />
                      </div>
                      <div className="innerCheckContainer">
                        Color:
                        <input type="text" />
                      </div>
                      <div className="innerCheckContainer">
                        Last changed:
                        <input type="text" />
                      </div>
                    </div>
                    Note: Client uses diapers
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Pain</td>
                  <td className="systemAssessmiddle">
                    <div className="systemAssessContent">
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          No pain
                        </div>
                      </div>

                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Location
                        </div>
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Onset
                        </div>
                      </div>

                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Duration
                        </div>
                      </div>
                      <div className="checkboxContainer">
                        <div className="innerCheckContainer">
                          <input type="checkbox" />
                          Pain description:
                        </div>
                        <div className="checkboxContainer">
                          <div className="innerCheckContainer">
                            Note: <input type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Medications</td>
                  <td className="NAmiddle"></td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Medication Allergies</td>
                  <td className="NAmiddle">NKDA</td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Current Treatments</td>
                  <td className="NAmiddle">None reported</td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Current Therapy</td>
                  <td className="NAmiddle">None reported</td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Dental Care</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Does Client have dental problems?
                        <input type="checkbox" /> Yes
                        <input type="checkbox" /> No
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Is Client under care of Dentist?
                        <input type="checkbox" /> Yes
                        <input type="checkbox" /> No
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">Dental State:</div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> No Dentures
                        <input type="checkbox" /> Dentures Damaged
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Full Upper
                        <input type="checkbox" /> No Dentures
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Full Lower
                        <input type="checkbox" /> Not Wearing Dentures
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Partial Denture
                        <input type="checkbox" /> No Teeth
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <div>Other:</div>
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Can Client chew food effectively?
                        <input type="checkbox" /> Yes
                        <input type="checkbox" /> No
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Dentist’ Name:
                        <div className="NAinputResultSection">asdasd</div>
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Dentist’s Phone Number:
                        <div className="NAinputResultSection">asdasd</div>
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Vision</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Unimpaired
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Adequate for Personal Safety
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Blind-Safe in Familiar locale
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Blind- Requires Assistance
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Distinguishes Only Light Or
                        Dark
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">Wears Glasses:</div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Yes
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> No
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Hearing</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Unimpaired
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Mild Impairment (refuses to
                        wear hearing aid- removes)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Moderate Impairment but Not a
                        Threat to Safety
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Impaired –Safety threat
                        exists.
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Totally Deaf
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        {" "}
                        Uses Hearing Aid(s):
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Right Ear
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Left Ear
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> No
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Psychosocial</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Cooperative (As reported)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Uncooperative
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Grieving
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Demanding
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Anxious
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Weeping
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Withdrawn
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Other:{" "}
                        <div className="NAinputResultSection">asdasd</div>
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Mental Health</td>
                  <td className="NAmiddle">
                    <div className="NAmiddleContainerGrid">
                      <div>
                        <div className="NAunderlineLabel">Attitude</div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Cooperative
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Indifferent
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Resistive
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Demanding
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Suspicious
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAunderlineLabel">Appearance</div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Well Groomed
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Adequate
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Disheveled
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Inappropriately Dressed
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Not Dressed
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAunderlineLabel">Self-Direction</div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Independent
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Needs Motivation
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Dependent
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Needs Direction
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Hostile
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAunderlineLabel">Behavior</div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Normal
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Wandering
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Sundowning
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Restless
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Hostile
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Withdrawn
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Self-Destructive
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Safety Hazard
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Verbal
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Physical
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAunderlineLabel">Influence</div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Appropriate
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Inappropriate
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Anxious
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Blunted
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Euphoric
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Depressed
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Angry
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Mood Swings
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAunderlineLabel">Thought Content</div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Normal
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Delusions
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Obsessions
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Phobias
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Persecutory
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Guilt
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Can't Assess
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Aggressive
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAunderlineLabel">Perceptions</div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Normal
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Hallucinations
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Auditory
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Visual
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Other
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAunderlineLabel">Cognition</div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Normal
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Impairment
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Mild
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Moderate
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Severe
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAunderlineLabel">Insight</div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Good
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Partial
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> None
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAunderlineLabel">Judgment</div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Good
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Adequate
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Poor
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="NAleft"></td>
                </tr>
                <tr className="NAresultContainer">
                  <td className="NAtitleforCategory">Living Habits</td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Smoking Habits</td>
                  <td className="NAmiddle">
                    <div className="NAmiddleContainerGrid">
                      <div>
                        <div className="NAunderlineLabel">Client Smokes</div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Yes
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> No (As reported)
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAunderlineLabel">
                          Degree of Problem
                        </div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> No Problem
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Some Problem
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Major Problem
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Alcohol Consumption</td>
                  <td className="NAmiddle">
                    <div className="NAmiddleContainerGrid">
                      <div>
                        <div className="NAunderlineLabel">Client Drinks</div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Yes
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> No (As reported)
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAunderlineLabel">
                          Degree of Problem
                        </div>
                        <div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> No Problem
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Some Problem
                            </div>
                          </div>
                          <div className="NAflexcontainer">
                            <div className="NAcheckboxLabel">
                              <input type="checkbox" /> Major Problem
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Current Diet</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Regular
                        <input type="checkbox" /> SOFT (As reported)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Low Salt
                        <input type="checkbox" />
                      </div>
                      <div className="NAcheckboxLabel">
                        Low Fat
                        <input type="checkbox" />
                      </div>
                      <div className="NAcheckboxLabel">
                        Diabetic
                        <input type="checkbox" />
                      </div>
                      <div className="NAcheckboxLabel">
                        Vegetarian
                        <input type="checkbox" />
                      </div>
                      <div className="NAflexcontainer">
                        <div className="NAcheckboxLabel">
                          Other: eats vegetarian
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Allergies:</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">To Food/Other: NKFA</div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">
                    Eating Habits (appetite)
                  </td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Good (As reported)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Fair
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Poor
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">Comments:</div>
                    </div>
                  </td>
                  <td className="NAleft"></td>
                </tr>
                <tr className="NAresultContainer">
                  <td className="NAtitleforCategory">COMMUNICATION</td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Language Spoken</td>
                  <td className="NAmiddle">
                    <div className="NAmiddleContainerGrid">
                      <div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> English
                          </div>
                        </div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> French
                          </div>
                        </div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Chinese
                          </div>
                        </div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Japanese
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Italian
                          </div>
                        </div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Spanish
                          </div>
                        </div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Russian
                          </div>
                        </div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> East Indian
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Other: Nepali spoken in home.
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Speech</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Unimpaired. (Communicates
                        needs through gestures and facial expressions. As
                        reported)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Simple Phrases -
                        Understandable
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Simple Phrases - Partially
                        Understandable
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Isolated Words –
                        Understandable
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Speech Not Understandable Or
                        Does Not Make Sense
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Does Not Speak
                      </div>
                    </div>
                    <br />
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        If Client Cannot Speak, Indicate Method of
                        Communicating:
                      </div>
                    </div>
                    <br />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">Method is: N/A</div>
                    </div>

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Effective
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Partially Effective
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Moderately Effective
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Not Effective
                      </div>
                    </div>
                    <br />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        NOTE: Client has cognitive impairment. Uses his facial
                        expressions, and hand gestures to communicate.
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Understanding:</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Unimpaired
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Understands Simple Phrases
                        Only
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Understands Key Words Only
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Understanding Unknown
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Not Responsive
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        NOTE: Client has cognitive impairment. Uses his facial
                        expressions, and hand gestures to communicate.
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NAresultContainer">
                  <td className="NAtitleforCategory">
                    ACTIVITIES OF DAILY LIVING
                  </td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Mobility Aids</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Uses Cane
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Uses Walker
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Uses Crutches
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Uses Wheelchair:
                      </div>
                      <div className="NAflexcontainer">
                        <div className="NAcheckboxLabel">
                          Manual
                          <input type="checkbox" />
                        </div>
                        <div className="NAcheckboxLabel">
                          Electric
                          <input type="checkbox" />
                        </div>
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Uses Grab Bars
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Other Prosthesis Or Aid: Uses human assistance, as
                        needed.
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Ambulation</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent in Normal
                        Environments
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent only in Specific
                        Environment
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Requires Supervision
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Requires Occasional or Minor
                        Assistance
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Requires Significant or
                        Continued Assistance
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Transferring</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Needs Supervision transferring
                        to:&nbsp;
                      </div>
                      <div className="NAcheckboxLabel">
                        Bed
                        <input type="checkbox" />
                      </div>
                      <div className="NAcheckboxLabel">
                        Chair
                        <input type="checkbox" />
                      </div>
                      <div className="NAcheckboxLabel">
                        Toilet
                        <input type="checkbox" />
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Needs Intermittent Assistance
                        transferring to:&nbsp;
                      </div>
                      <div className="NAcheckboxLabel">
                        Bed
                        <input type="checkbox" />
                      </div>
                      <div className="NAcheckboxLabel">
                        Chair
                        <input type="checkbox" />
                      </div>
                      <div className="NAcheckboxLabel">
                        Toilet
                        <input type="checkbox" />
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Needs Continued Assistance
                        transferring to:&nbsp;
                      </div>
                      <div className="NAcheckboxLabel">
                        Bed
                        <input type="checkbox" />
                      </div>
                      <div className="NAcheckboxLabel">
                        Chair
                        <input type="checkbox" />
                      </div>
                      <div className="NAcheckboxLabel">
                        Toilet
                        <input type="checkbox" />
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Completely Dependent for All
                        Movements
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Bathing</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent in Bathtub or
                        Shower
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent with Mechanical
                        Aids (E.g. bath seat)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Requires Minor Assistance or
                        Supervision:
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NASubcolumnConatiner NAmiddleSubMarginLeft">
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" />
                          Getting in and Out of Tub/Shower
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" />
                          Turning Taps On and Off
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" />
                          Washing Back
                        </div>
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Requires Continued Assistance
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Resists Assistance
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Other: (As reported)
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Dressing</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent
                      </div>
                    </div>
                    <div className="NAflexColumnContainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Supervision or Needs some
                        help:
                      </div>
                      <div className="NASubcolumnConatiner NAmiddleSubMarginLeft">
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" />
                          Selecting Appropriate Clothing
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" />
                          Coordinating Colors
                        </div>
                      </div>
                    </div>
                    <div className="NAflexColumnContainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Periodic or Daily Help Needed:
                      </div>
                      <div className="NASubcolumnConatiner NAmiddleSubMarginLeft">
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" />
                          Putting on Clothing
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" />
                          Doing up Buttons, Laces, Zippers
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" />
                          Pulling on Trousers, Socks, Shoes
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" />
                          Determining Condition or Cleanliness of Clothing
                        </div>
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">(As reported)</div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Grooming &amp; Hygiene</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Requires Reminder, Motivation
                        &amp;/or Direction
                      </div>
                    </div>
                    <div className="NAflexColumnContainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Requires Assistance with Some
                        Things
                      </div>
                      <div className="NASubcolumnConatiner NAmiddleSubMarginLeft">
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" />
                          Putting Toothpaste on Toothbrush
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" />
                          Using Electric Razor
                        </div>
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Requires Total Assistance
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Resists Assistance
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Eating</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent with Special
                        Provision for Disability
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Requires Intermittent Help
                        With:
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Cutting Up/Pureeing Food
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Must Be Fed
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Resists Feeding
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Bladder Control</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Totally Continent
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Needs Routine Toileting or
                        Reminder
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Incontinent Due to
                        Identifiable Factors (As reported)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Incontinent Once Per Day
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Incontinent More than Once per
                        Day
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Bowel Control</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Has Total Control
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Needs Routine Toileting or
                        Reminder
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> No Bowel Control Due to
                        Identifiable Factors (As reported)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Loses Bowel Control Once Per
                        Day
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Loses Bowel Control More than
                        Once per Day
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Toileting</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Requires Raised Toilet Seat or
                        Commode
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Has Difficulty with Buttons,
                        Zippers (As reported)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Needs Help with Aids (E.g.
                        Catheter, Condom Drainage, etc.)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Other:
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Exercising</td>
                  <td className="NAmiddle">
                    <div className="NAflexColumnContainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Exercises Regularly:
                      </div>
                      <div className="NASubcolumnConatiner NAmiddleSubMarginLeft">
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> Daily
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> Alternate Days
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> Daily
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> Alternate Days
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> Twice a Week
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> Weekly
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> Other: Reports walks around
                          the house and outdoors with assistance (walker used
                          indoors)
                        </div>
                      </div>
                    </div>
                    <div className="NABrSpace" />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Time and/or Distance: Reports
                        as needed and as tolerated
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Recent Changes to Exercise
                        Regime_____________________
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Exercise Alone
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Exercises with Attendant
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Other:
                      </div>
                    </div>
                    <div className="NABrSpace" />
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">(As reported)</div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NAresultContainer">
                  <td className="NAtitleforCategory">
                    INSTRUMENTAL ACTIVITIES OF DAILY LIVING
                  </td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Preparing Food</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Adequate if Ingredients
                        Supplied
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Can Make or Buy Meals but Diet
                        is Inadequate
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Physically or Mentally Unable
                        to Prepare Food
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> No Opportunity to Prepare Food
                        or Chooses Not to Prepare Food:
                      </div>
                    </div>
                    <div className="NABrSpace" />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Reports Family prepares food
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Housekeeping</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Generally Independent but
                        Needs Help With Heavier Tasks
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Can Perform Only Light Tasks
                        Adequately
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Performs Light Tasks but Not
                        Adequately
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Needs Regular Help and/or
                        Supervision
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> No Opportunity to Do Housework
                        or Chooses Not to Do Housework
                      </div>
                    </div>
                    <div className="NABrSpace" />
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Family does housekeeping (As reported)
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Shopping</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent But For Small
                        Items Only
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Can Shop if Accompanied
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Physically or Mentally Unable
                        to Shop
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> No Opportunity to Shop or
                        Chooses Not to Shop
                      </div>
                    </div>
                    <div className="NABrSpace" />
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Family shops for the client. (As reported)
                      </div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Transportation</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Uses Private Vehicle
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Uses Taxi or Bus
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Must be Accompanied (As
                        reported)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Must be Driven
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Physically or Mentally Unable
                        to Travel (As reported)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Needs Ambulance for
                        Transporting
                      </div>
                    </div>
                    <div className="NABrSpace" />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">(As reported)</div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Telephone</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Can Dial Well Known Numbers
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Answers Telephone Only
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Physically or Mentally Unable
                        to Use Telephone
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> No Opportunity to Use
                        Telephone or Chooses Not to Use Telephone
                      </div>
                    </div>
                    <div className="NABrSpace" />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">(As reported)</div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Medication/ Treatments</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Completely Responsible for
                        Self
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Requires Reminder or
                        Assistance
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Responsible if Medications
                        Prepared in Blister pack
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Physically or Mentally Unable
                        to Take Medications and Conduct Treatments
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Resists Taking Medication or
                        Conducting Treatments
                      </div>
                    </div>
                    <div className="NABrSpace" />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">(As reported)</div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NAresultContainer">
                  <td className="NAtitleforCategory">ATTENDANT PROFILE</td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Attendant</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Independent
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Needs an Attendant
                      </div>
                    </div>
                    <div className="NAflexColumnContainer">
                      <div className="NAcheckboxLabel">
                        Frequency of Attendant Assistance:
                      </div>
                      <div className="NASubcolumnConatiner NAmiddleSubMarginLeft">
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> Intermittent
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> Constantly
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> During Day
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> During Night
                        </div>
                      </div>
                    </div>
                    <div className="NAflexColumnContainer">
                      <div className="NAcheckboxLabel">
                        Attendant Needs Met by:
                      </div>

                      <div className="NASubcolumnConatiner NAmiddleSubMarginLeft">
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> Spouse
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> Friend
                        </div>
                        <div className="NAcheckboxLabel">
                          <input type="checkbox" /> Family
                        </div>
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Other: Caregiver Aide (family
                        member)
                      </div>
                    </div>
                    <div className="NABrSpace" />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">(As reported)</div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NAresultContainer">
                  <td className="NAtitleforCategory">SOCIAL PROFILE</td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Housing</td>
                  <td className="NAmiddle">
                    <div className="NAmiddleContainerGrid">
                      <div>
                        <div className="NAflexColumnContainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> House
                          </div>
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Apartment
                          </div>
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Condominium
                          </div>
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Room
                          </div>
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Urban
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAflexColumnContainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Self Owned
                          </div>
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Rental
                          </div>
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Mobile Home
                          </div>
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Facility
                          </div>
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Rural
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="NABrSpace" />

                    <div className="NAcheckboxLabel">(As reported)</div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Living Companions</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Lives Alone
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Lives with Spouse or Spousal
                        Equivalent
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Lives With Adult Children
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Lives With Child(ren)
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Lives with Other Adult Male
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Lives with Other Adult Female
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        Principal Helper: Caregiver Aide (parent)
                      </div>
                    </div>
                    <div className="NABrSpace" />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">(As reported)</div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Religion &amp; Culture</td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Ethnicity:
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Religion:
                      </div>
                    </div>
                    <div className="NABrSpace" />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">(As reported)</div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NAresultContainer">
                  <td className="NAtitleforCategory">FINANCIAL PROFILE</td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Financial Benefits </td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Financial Benefits:
                      </div>
                    </div>
                    <div className="NASubcolumnConatiner NAmiddleSubMarginLeft">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Old Age Security Pension
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Guaranteed Income Supplement
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Gains for Senior
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> War Veterans Allowance or
                        Disability Pension
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Company Pension
                      </div>
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Other
                      </div>
                    </div>
                    <div className="NABrSpace" />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">(As reported)</div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Financial Management </td>
                  <td className="NAmiddle">
                    <div className="NAmiddleContainerGrid">
                      <div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Self
                          </div>
                        </div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Family
                          </div>
                        </div>

                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Public Trustee
                          </div>
                        </div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Other:
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Spouse
                          </div>
                        </div>
                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Friend
                          </div>
                        </div>

                        <div className="NAflexcontainer">
                          <div className="NAcheckboxLabel">
                            <input type="checkbox" /> Power of Attorney
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="NABrSpace" />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">(As reported)</div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">Financial Arrangements </td>
                  <td className="NAmiddle">
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Appropriate
                      </div>
                    </div>
                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">
                        <input type="checkbox" /> Not Appropriate
                      </div>
                    </div>
                    <div className="NABrSpace" />

                    <div className="NAflexcontainer">
                      <div className="NAcheckboxLabel">(As reported)</div>
                    </div>
                  </td>
                  <td className="NALeft"></td>
                </tr>
                <tr className="NAresultContainer">
                  <td className="NAtitleforCategory">ADDITIONAL INFORMATION</td>
                </tr>
                <tr className="NASubResultContainer">
                  <td className="NAresultLeftTitle">ADDITIONAL INFORMATION</td>
                  <td className="NAmiddle"></td>
                  <td className="NALeft"></td>
                </tr>
                <div className="NABrSpace" />
                <div className="NAnameAndSignConatiner">
                  <div>
                    <div className="NAinputSection">
                      {" "}
                      <FormikController
                        control="textfield"
                        fullWidth="false"
                        variant="standard"
                        // className="inputSection"
                        // InputProps={{ disableUnderline: true }}
                      />
                    </div>

                    <div> RN Name (Print)</div>
                  </div>
                  <div></div>
                  <div>
                    <div className="NAinputSection">
                      {" "}
                      <FormikController
                        control="textfield"
                        fullWidth="false"
                        variant="standard"
                        // className="inputSection"
                        // InputProps={{ disableUnderline: true }}
                      />
                    </div>

                    <div>RN Signature </div>
                  </div>
                  <div>
                    <div className="NAinputSection">
                      {" "}
                      <FormikController
                        control="textfield"
                        fullWidth="false"
                        variant="standard"
                        // className="inputSection"
                        // InputProps={{ disableUnderline: true }}
                      />
                    </div>

                    <div> Date</div>
                  </div>
                  <div>
                    <div className="NAinputSection">
                      {" "}
                      <FormikController
                        control="textfield"
                        fullWidth="false"
                        variant="standard"
                        // className="inputSection"
                        // InputProps={{ disableUnderline: true }}
                      />
                    </div>

                    <div> Client’s or Representative’s Signature</div>
                  </div>
                  <div>
                    <div className="NAinputSection">
                      {" "}
                      <FormikController
                        control="textfield"
                        fullWidth="false"
                        variant="standard"
                        // className="inputSection"
                        // InputProps={{ disableUnderline: true }}
                      />
                    </div>

                    <div> Date</div>
                  </div>
                </div>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NursingAssesmentDoc;

import React, { useEffect, useState } from "react";
import { styles } from "./CommonUtilsPdf";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import ClientStore from "../../../Store/ClientStore";
import PdfFooter from "./PdfFooter";
const DoctorInformation = () => {
  const [doctorInfo, setDoctorInfo] = useState({});
  useEffect(() => {
    setDoctorInfo(ClientStore?.individualDocterInfoData);
  }, [ClientStore?.individualDocterInfoData]);

  const rows = [
    {
      title: "Client Name",
      value: `${doctorInfo?.clientFirstName} ${doctorInfo?.clientMiddleName} ${doctorInfo?.clientLastName}`,
    },
    {
      title: "Doctor",
      value: `${doctorInfo?.doctor}`,
    },
    {
      title: "Phone",
      value: `${doctorInfo?.phoneNo}`,
    },
    {
      title: "Address",
      value: `${doctorInfo?.address}`,
    },
  ];

  const DoctorTitle = () => (
    <View style={styles.doctorTitle}>
      <Text style={[styles.underline, styles.reportTitle]}>
        Doctor Information
      </Text>
    </View>
  );
  const DoctorList = () => (
    <View style={styles.doctorContainer}>
      {rows.map((item) => {
        return (
          <View style={styles.doctorInfo}>
            <View style={styles.infoTitle}>
              <Text>{item.title}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text>{item.value}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <DoctorTitle />
        <DoctorList />
        <PdfFooter />
      </Page>
    </Document>
  );
};

export default DoctorInformation;

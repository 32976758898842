import * as Yup from "yup";
export const YupValidation = {
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Enter capital,digit,small and special character at least eight digits"
    ),
  email: Yup.string()
    .email("Email is invalid")
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    ),
  branches: Yup.string().required("Select any one branches"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  oldPassword: Yup.string().required("Enter your current password"),
  nameTitle: Yup.string(),
  nameSuffix: Yup.string(),
  optionalName: Yup.string().matches(
    /^[a-zA-Z0-9\s]+$/,
    "Can only contain letters, numbers, and spaces"
  ),
  firstName: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      "Can only contain letters, numbers, and spaces"
    )
    .min(3, "First name must be at least 3 characters")
    .required("First name required"),
  middleName: Yup.string().matches(
    /^[a-zA-Z0-9\s]+$/,
    "Can only contain letters, numbers, and spaces"
  ),
  lastName: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      "Can only contain letters, numbers, and spaces"
    )
    .min(3, "Last name must be at least 3 characters")
    .required("Last name is required"),
  gender: Yup.string().required("Please select one options"),
  dateOfBirth: Yup.string().required("Date of birth required"),
  lastAuditDate: Yup.string().required("Last audit date required"),
  optionalphone: Yup.string().matches(
    /^[0-9\s]+$/,
    "Phone must contain only numbers"
  ),
  phone: Yup.string()
    .matches(/^[0-9\s]+$/, "Phone must contain only numbers")
    .required("Phone Required"),
  maritalStatus: Yup.string().required("Marital status required"),
  immigration: Yup.string().required("Immigration status required"),
  approvedHrs: Yup.string().matches(
    /^[0-9\s]+$/,
    "Working Hour must contain only numbers"
  ),
  ethnicity: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      "can only contain letters, numbers, and spaces"
    )
    .required("Ethnicity is required"),
  street: Yup.string().required("Street is required"),
  city: Yup.string()
    // .matches(
    //   /^[a-zA-Z0-9\s]+$/,
    //   "Can only contain letters, numbers, and spaces"
    // )
    .required("City is required"),

  zipCode: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      "Can only contain letters, numbers, and spaces"
    )
    .required("ZIP code is required"),
  state: Yup.string()
    // .matches(/^[a-zA-Z\s]+$/, "can only contain letters, and spaces")
    .required("State is required"),
  county: Yup.string(),
  // .matches(/^[a-zA-Z\s]+$/, "can only contain letters, and spaces")
  // .required("County is required"),
  region: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      "Can only contain letters, numbers, and spaces"
    )
    .required("region is required"),

  referralSource: Yup.string(),
  referralPerson: Yup.string(),
  employeeType: Yup.string().required("Employee Type is required"),
  applicationDate: Yup.string().required("Application Date is required"),
  cocIncharge: Yup.number(),

  licensesNumber: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, "Can only contain letters,numbers, and spaces")
    .required("License Number is required"),
  status: Yup.string().required("Status is required"),
  joinDate: Yup.string().required("Join Date is required"),

  ECfirstName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Can only contain letters, numbers, and spaces")
    .min(3, "First name must be at least 3 characters")
    .required("First Name Required"),
  ECmiddleName: Yup.string().matches(
    /^[a-zA-Z\s]+$/,
    "Can only contain letters, numbers, and spaces"
  ),
  EClastName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Can only contain letters, numbers, and spaces")
    .min(3, "Last name must be at least 3 characters")
    .required("Last Name Required"),
  optionalEmail: Yup.string()
    .email("Email is invalid")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address"
    ),
  relationship: Yup.string().required("Relationship is required"),

  employeeId: Yup.string(),
  resetCode: Yup.string().required("Reset code is required"),
  file_type: Yup.mixed()
    .required("Required")
    .test("fileType", "Only PDF files are allowed", (value) => {
      if (value) {
        return value && value.type === "application/pdf";
      }
      return true;
    }),
  file_type_without_require: Yup.mixed().test(
    "fileType",
    "Only PDF files are allowed",
    (value) => {
      if (value) {
        return value && value.type === "application/pdf";
      }
      return true;
    }
  ),
  expiryDate: Yup.string().required("Required"),
  expiryDate_without_require: Yup.string(),
  uniqueCode: Yup.string().required("Unique Code is required"),
  logChanges: Yup.string().required("Required"),

  doctor: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      "Can only contain letters, numbers, and spaces"
    )
    .required("Doctor name required"),

  address: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      "Can only contain letters, numbers, and spaces"
    )
    .required("Address required"),

  caseNote: Yup.string().required("Case note required"),
  diagnosis: Yup.string().required("Diagnosis required"),
  requiredOnly: Yup.string().required("Required"),
  allergies: Yup.string().required("Please mention the allergies"),
  specialInstructions: Yup.string().required("Special Instruction is required"),
  specificDayOnly: Yup.string()
    .required("Date is required")
    .test("is-thursday", "Selected date is not a Thursday", function (value) {
      const date = new Date(value);
      return date.getUTCDay() === 4;
    }),
};

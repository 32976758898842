import React, { useMemo, useState } from "react";

import { observer } from "mobx-react";
import CareGiverStore from "../../../Store/CareGiverStore";
import { NotificationURL } from "../../../Url/Url";
import axios from "axios";
import AgencyStore from "../../../Store/AgencyStore";
import FormikController from "../../../components/FormikController/FormikController";
import { leftStyles, centerStyles } from "../../../Constants/Constants";
import {
  CellRenderer,
  dateCellRenderer,
  notificationCellRenderer,
  useOnGridReady,
} from "../../../Utils/AgGridUtils";
import GlobelStore from "../../../Store/GlobelStore";
import NotificationStore from "../../../Store/NotificationStore";
import { useNavigate } from "react-router-dom";
const NotificationClientTable = observer(() => {
  const navigate = useNavigate();

  const [onGridReady, onFirstDataRendered] = useOnGridReady(
    NotificationStore,
    "clientNotificationAgGrid"
  );

  const fullNameCellRenderer = (params) => {
    const onFullNameClick = () => {
      const employeeId = params.data.clientProfileId;
      CareGiverStore.setIsCareGiverProfile(true);
      CareGiverStore.setIsUniqueCodeEntered(false);
      GlobelStore.setEmployeeData(params.data);

      navigate("/agency/Client_profile/" + employeeId);
    };

    return (
      <div
        style={{
          color: params.data.isViewed === false ? "Black" : "",
          fontWeight: params.data.isViewed === false ? "bold" : "",
        }}
        onClick={onFullNameClick}
        class="ag-inner-cell"
        onMouseEnter={(e) => {
          e.target.style.color = "#4198ff";
        }}
        onMouseLeave={(e) => {
          e.target.style.color = "";
        }}
      >
        {params.value}
      </div>
    );
  };

  const columnDefs = useMemo(
    () => [
      {
        field: "isViewed",
        headerName: "Status",
        sortable: false,
        width: 10,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          const handleCheckboxChange = (values) => {
            AgencyStore.setIsLoading(true);
            const params = {
              notificationId: values.id,
              isViewed: !values.isViewed,
            };
            axios
              .post(NotificationURL.SET_CLIENT_NOTIFICATION_VIEW, params, {
                headers: { Authorization: localStorage.getItem("loginToken") },
              })
              .then((res) => {
                NotificationStore.getClientNotification();
                NotificationStore.getEmpClientUnseenNotificationCount();

                AgencyStore.setIsLoading(false);
              })
              .catch((err) => {
                AgencyStore.setIsLoading(false);
                AgencyStore.showAlert("error", "Failed to Change statues");
              });
          };

          return (
            <div>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(params.data)}
                checked={params.data.isViewed}
              />
            </div>
          );
        },
      },
      {
        field: "clientFullName",
        headerName: "Name",
        width: 40,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },
      {
        field: "title",
        headerName: "Title",
        width: 70,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: notificationCellRenderer,
      },
      {
        field: "description",
        headerName: "Description",
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: CellRenderer,
      },

      {
        field: "date",
        headerName: "Date",
        width: 40,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: dateCellRenderer,
      },
    ],
    []
  );

  const getRowStyle = (params) => {
    if (params.data.isViewed === true) {
      return { background: "lightgray" };
    }
    return null;
  };

  return (
    <FormikController
      rowData={NotificationStore?.clientNotificationAllData?.resultSet}
      control="agGrid"
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      getRowStyle={getRowStyle}
      onFirstDataRendered={onFirstDataRendered}
    />
  );
});

export default React.memo(NotificationClientTable);

import React, { useEffect, useMemo, useState } from "react";
import { createColumnDef, useOnGridReady } from "../../../Utils/AgGridUtils";
import FormikController from "../../../components/FormikController/FormikController";
import { centerStyles, leftStyles } from "../../../Constants/Constants";
import ClientStore from "../../../Store/ClientStore";
import { useNavigate, useParams } from "react-router-dom";
import { openPdf } from "../PdfViewer/CommonUtilsPdf";
import HomeSafetyPdf from "./HomeSafetyPdf";
import CareGiverStore from "../../../Store/CareGiverStore";

const HomeSafetyList = () => {
  const navigate = useNavigate();
  const [onGridReady] = useOnGridReady(ClientStore, "safetyAgGrid");
  const { clientId } = useParams();
  const [safetyList, setSafetyList] = useState([]);

  const columnDefs = useMemo(
    () => [
      createColumnDef("clientName", "Client Name", leftStyles, "", "", 90),
      createColumnDef(
        "evalCompletedBy",
        "Evaluation Completed By",
        leftStyles,
        "",
        "",
        90
      ),
      createColumnDef("visitType", "Visit Type", leftStyles, "", "", 70),
      createColumnDef("systemEntryDate", "Upload Date", leftStyles, "", "", 80),
      createColumnDef(
        "lastUpdatedDate",
        "Modified Date",
        leftStyles,
        "",
        "",
        80
      ),

      {
        headerName: "Action",
        sortable: false,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        width: 100,
        cellRenderer: (params) => {
          const handleButtonClick = () => {
            console.log(params.data);
            ClientStore.setSafetyRecordList(params.data);
            navigate(`/agency/Client_profile/home_safety/${params.data.id}`);
          };
          const handleDownloadButton = () => {
            openPdf(<HomeSafetyPdf dataList={params.data} />);
          };
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              {/* <div className="manageClick" onClick={() => handleButtonClick()}>
                Edit
              </div> */}
              <div
                className="docViewText"
                onClick={() => handleDownloadButton()}
              >
                View
              </div>
            </div>
          );
        },
      },
    ],
    []
  );
  useEffect(() => {
    const fetchSafetyList = async () => {
      try {
        await ClientStore.getHomeSafetyList(clientId);
        getRecordList();
      } catch (error) {
        console.error("Error fetching safety list:", error);
      }
    };

    fetchSafetyList();
  }, []);

  const getRecordList = () => {
    const data = [];
    Object.entries(ClientStore.safetyRecordList).forEach(
      ([category, items]) => {
        if (items.length > 0) {
          items.forEach((eachItem) => {
            data.push(eachItem);
          });
        }
      }
    );
    setSafetyList(data);
    CareGiverStore.setTableHeight(data?.length);
  };

  return (
    <FormikController
      rowData={safetyList}
      control="agGrid"
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default HomeSafetyList;

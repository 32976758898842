import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../CSS/Input.css";

const SelectByInput = (props) => {
  const { title, ...otherProps } = props;

  return (
    <>
      <div className="title">{title}</div>

      <Autocomplete
        fullWidth
        size="small"
        options={otherProps.options}
        onChange={otherProps.handleChange}
        disabled={otherProps.disabled}
        value={otherProps.values}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
          />
        )}
      />
      <div className="error">
        {otherProps.touch && otherProps.errorname ? otherProps.errorname : ""}
      </div>
    </>
  );
};

export default SelectByInput;

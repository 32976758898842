import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "../../../CSS/Profile.css";
import { useParams } from "react-router-dom";
import ChangeLog from "../LogList/ChangeLog";
import ClientSecurity from "./ClientSecurity";
import GlobelStore from "../../../Store/GlobelStore";
import ClientStore from "../../../Store/ClientStore";
import ClientDoctorMain from "./ClientDoctorMain";
import ClientDocumentMain from "./ClientDocumentMain";
import NursingAssessmentTab from "../NursingAssessment/NursingAssessmentTab";
import ClientProfileNotesMain from "./ClientProfileNotesMain";
import ClientSchedule from "./ClientSchedule";
import PageTitle from "../../PageTitle";
import MedicationRecordsTab from "../MedicationRecords/MedicationRecordsTab";
import { PiNotePencil } from "react-icons/pi";
import HomeSafetyTab from "../HomeSafety/HomeSafetyTab";
import ClientCarePlanTab from "../ClientCarePlan/ClientCarePlanTab";
import CareGiverEvaluationFormTab from "../CareGiverEvaluationForm/CareGiverEvaluationFormTab";
import CareGiverPlanOfServiceTab from "../CareGiverPlanOfService/CareGiverPlanOfServiceTab";
import ParticipantReviewAndCareGiverSupervisoryVisitTab from "../ParticipantReviewAndCareGiverSupervisoryVisit/ParticipantReviewAndCareGiverSupervisoryVisitTab";
import CompetencyChecklistPersonalCareAideTab from "../CompetencyChecklistPersonalCareAide/CompetencyChecklistPersonalCareAideTab";
import NurseClientView from "../Nurse/NurseClientView";
import Icon from "../../../components/Icons/Icon";
import PlanOfService from "./planOfService/PlanOfService";
import ClientSignature from "./ClientSignature";
import ClientDocumentClientInfoPackagMain from "./ClientDocumentClientInfoPackagMain";

const ClientViewPage = observer(({ customerType }) => {
  const [value, setValue] = React.useState("1");
  const { clientId } = useParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (clientId) {
      ClientStore.setClientAllData([]);
      const fetchData = async () => {
        ClientStore.getClientProfile(clientId);
        ClientStore.getClientDocument(clientId);
        ClientStore.getDoctorInfo(clientId);

        ClientStore.getDoctorCaseNotes(clientId);
        ClientStore.getClientProfileCaseNotes(clientId);
        ClientStore.getClientProfileDiagnosis(clientId);
        ClientStore.getPosDoc(clientId);
        ClientStore.getNursingAssessmentDocuments(
          clientId,
          "NURSING_ASSESSMENT"
        );
        ClientStore.getNursingAssessmentDocuments(
          clientId,
          "MEDICATION_RECORDS_LIST"
        );
        ClientStore.getNursingAssessmentDocuments(clientId, "HOME_SAFETY");
        ClientStore.getNursingAssessmentDocuments(clientId, "CLIENT_CARE_PLAN");
        ClientStore.getNursingAssessmentDocuments(
          clientId,
          "CAREGIVER_EVALUATION_FORM"
        );
        ClientStore.getNursingAssessmentDocuments(
          clientId,
          "CAREGIVER_PLAN_OF_SERVICE"
        );
        ClientStore.getNursingAssessmentDocuments(
          clientId,
          "COMPETENCY_CHECKLIST_PERSONAL_CARE_AIDE"
        );
        ClientStore.getNursingAssessmentDocuments(
          clientId,
          "PARTICIPANT_REVIEW_AND_CAREGIVER_SUPERVISORY_VISIT"
        );

        ClientStore.getClientCalendar(clientId);
        GlobelStore.getClientChangeLogs(clientId);
        ClientStore.getClientEmployeeRelation(clientId);
        GlobelStore.getEmployeeList("CareGiver");
        GlobelStore.getEmployeeList("RN");
        ClientStore.getClientSignature(clientId);
        ClientStore.getHomeSafetyList(clientId);
      };

      fetchData();
    }
  }, [clientId]);

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle={customerType === "client" ? "Client" : "Leads"}
          subHeaderTitle={
            customerType === "client"
              ? "Customer/Client/Profile"
              : "Leads/Profile"
          }
        />
      </div>

      <div className="profileMainContainer">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              className="profileTabsMenu"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
              >
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="profile" className="profileEeachMenuIcon" />
                  }
                  label="Profile"
                  value="1"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="signature"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Signature"
                  value="19"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="doctor" className="profileEeachMenuIcon" />
                  }
                  label="Doctor Info"
                  value="9"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Document"
                  value="10"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label=" Client info Packag"
                  value="20"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="log" className="profileEeachMenuIcon" />
                  }
                  label="Logs"
                  value="8"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="careGiver"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Personal Care Aid"
                  value="17"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="nurse" className="profileEeachMenuIcon" />
                  }
                  label="Nurse"
                  value="18"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Nursing Assessment"
                  value="7"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Medication Records"
                  value="2"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Home Safety"
                  value="3"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Client Care Plan"
                  value="11"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Care Giver Evaluation Form"
                  value="12"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Care Giver Plan of Service"
                  value="13"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Competency Checklist Personal Care Aide"
                  value="14"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Participant Review and Care Giver Supervisory Visit"
                  value="15"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Plan of Service"
                  value="16"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="schedule"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Schedule"
                  value="5"
                />
                {GlobelStore.checkUserRole("AccountAndAccess") && (
                  <Tab
                    className="profileEachMenu"
                    icon={
                      <Icon
                        iconName="account"
                        className="profileEeachMenuIcon"
                      />
                    }
                    label="Account"
                    value="6"
                  />
                )}
              </TabList>
            </Box>
            <TabPanel className="profileEachTabPanel" value="1">
              <ClientProfileNotesMain />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="19">
              <ClientSignature />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="9">
              <ClientDoctorMain />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="10">
              <ClientDocumentMain />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="20">
              <ClientDocumentClientInfoPackagMain />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="8">
              <ChangeLog employeeCategory="Client" />
            </TabPanel>

            <TabPanel className="profileEachTabPanel" value="17">
              <NurseClientView employeeCategory="CareGiver" />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="18">
              <NurseClientView employeeCategory="Nurse" />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="7">
              <NursingAssessmentTab />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="2">
              <MedicationRecordsTab />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="3">
              <HomeSafetyTab />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="11">
              <ClientCarePlanTab />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="12">
              <CareGiverEvaluationFormTab />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="13">
              <CareGiverPlanOfServiceTab />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="14">
              <CompetencyChecklistPersonalCareAideTab />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="15">
              <ParticipantReviewAndCareGiverSupervisoryVisitTab />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="16">
              <PlanOfService />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="5">
              <ClientSchedule />
            </TabPanel>
            {GlobelStore.checkUserRole("AccountAndAccess") && (
              <TabPanel className="profileEachTabPanel" value="6">
                <ClientSecurity />
              </TabPanel>
            )}
          </TabContext>
        </Box>
      </div>
    </div>
  );
});

export default ClientViewPage;

import { observer } from "mobx-react";
import React from "react";

import "../../../CSS/Client.css";

import DocViewerList from "../DocViewerList/DocViewerList";

const ClientDocumentClientInfoPackagMain = observer(() => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <DocViewerList clientInfoPacket="clientInfoPacket" />
    </div>
  );
});

export default ClientDocumentClientInfoPackagMain;

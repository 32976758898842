import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";

import "../../../CSS/Client.css";

import CommunityOutReachCoordinatorList from "./CommunityOutReachCoordinatorList";
import EmployeeNotiEmailSmsList from "./EmployeeNotiEmailSmsList";
import CommunityCoordinatorStore from "../../../Store/CommunityCoordinatorStore";

const CommunityOutReachCoordinatorMainPage = observer(() => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="clientDoctorMainContaner">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
              >
                <Tab className="profileEachMenu" label="Send" value="1" />
                <Tab
                  className="profileEachMenu"
                  label="Send History"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel className="profileEachTabPanel" value="1">
              <CommunityOutReachCoordinatorList />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="2">
              <EmployeeNotiEmailSmsList
                category="employee"
                employeeName="CommunityCoordinator"
                storeName={CommunityCoordinatorStore}
                storeGrid="communityCoordinatorAgGrid"
              />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </>
  );
});

export default CommunityOutReachCoordinatorMainPage;

import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import FormikController from "../../../components/FormikController/FormikController";
import {
  dateCellRenderer,
  fullNameCellRenderer,
  useOnGridReady,
} from "../../../Utils/AgGridUtils";
import ClientStore from "../../../Store/ClientStore";
import { useParams } from "react-router-dom";
import {
  centerStyles,
  leftStyles,
  rightStyles,
} from "../../../Constants/Constants";
import { RiDeleteBin6Line } from "react-icons/ri";
import Icon from "../../../components/Icons/Icon";
import UpdateDigonisisPopUp from "./UpdateDigonisisPopUp";
import { Tooltip } from "@mui/material";
import AgencyStore from "../../../Store/AgencyStore";
import { ClientURL } from "../../../Url/Url";
import axios from "axios";
import ConfirmPopUp from "../../../components/ConfirmPopUp/ConfirmPopUp";

const ClientProfileDigonisis = observer(() => {
  const { clientId } = useParams();
  const [onGridReady, onFirstDataRendered] = useOnGridReady(
    ClientStore,
    "clientAgGrid"
  );

  const handleSaveButtonClick = () => {
    ClientStore.setIndividualDiagnosisData({});
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "SN",
        width: 100,
        filter: true,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: (params) => params.node.rowIndex + 1,
      },
      {
        field: "diagnosis",
        headerName: "Diagnosis",
        filter: true,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },
      {
        field: "savedDate",
        headerName: "Created Date",
        filter: true,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: dateCellRenderer,
      },

      {
        field: "lastUpdatedDate",
        headerName: "Last Updated Date",
        filter: true,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: dateCellRenderer,
      },
      {
        field: "update",
        headerName: "Action",
        sortable: false,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          const handleButtonClick = () => {
            ClientStore.setIndividualDiagnosisData(params.data);
          };
          return (
            <div className="actionContainer">
              <UpdateDigonisisPopUp
                type="clientDiagnosis"
                onConfirm={() => {}}
                clickHTML={
                  <Tooltip title="Edit">
                    <div
                      className="iconBox updateIcon"
                      onClick={() => {
                        ClientStore.setCaseNotestatus("update");
                        handleButtonClick();
                      }}
                    >
                      <Icon iconName="edit" />
                    </div>
                  </Tooltip>
                }
              />
              <ConfirmPopUp
                buttonLabel="Delete"
                title="Delete Diagnosis"
                content=" Please confirm your decision below."
                onConfirm={() => {
                  handleButtonClick();
                  deleteDiagnosisInfo();
                }}
                clickHTML={
                  <Tooltip title="Delete">
                    <div className="iconBox actionMarginLeft deleteIcon">
                      <Icon iconName="delete" />
                    </div>
                  </Tooltip>
                }
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const deleteDiagnosisInfo = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      clientProfileId: clientId,
      diagnosisId: ClientStore.individualDiagnosisData.id,
    };

    axios
      .post(ClientURL.DELETE_CLIENT_DIAGNOSIS, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          AgencyStore.setIsLoading(false);
          ClientStore.getClientProfileDiagnosis(clientId);
          ClientStore.setIndividualDiagnosisData({});
          AgencyStore.showAlert("success", "Successfully Deleted");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to Deleted");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to Deleted");
      });
  };
  return (
    <div>
      <div className="CDCNunderlineClickBtnContainer">
        <UpdateDigonisisPopUp
          type="clientDiagnosis"
          clickHTML={
            <div
              className="boderRoundedButton"
              onClick={() => {
                ClientStore.setCaseNotestatus("save");
                handleSaveButtonClick();
              }}
            >
              <Icon iconName="circleAdd" className="boderRoundedButtonIcon" />
              <span className="boderRoundedButtonText">Add Digonisis</span>
            </div>
          }
        />
      </div>

      <FormikController
        rowData={ClientStore.clientDiagnosisAllData.resultSet}
        control="agGrid"
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  );
});

export default ClientProfileDigonisis;

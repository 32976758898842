import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import { ImFilePdf } from "react-icons/im";
import Icon from "../../../components/Icons/Icon";
const DownloadLink = ({ document, fileName }) => {
  return (
    <PDFDownloadLink
      style={{ textDecoration: "none", color: "unset" }}
      document={document}
      fileName={fileName}
    >
      <div className="boderRoundedButton">
        <Icon iconName="pdf2" className="" />
        <span className="boderRoundedButtonText"> Download</span>
      </div>
    </PDFDownloadLink>
  );
};

export default DownloadLink;

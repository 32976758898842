import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ImWarning } from "react-icons/im";
import FormikController from "../FormikController/FormikController";
import { PaperComponent } from "../../Utils/Utils";

const ConfirmPopUp = ({
  title,
  content,
  onConfirm,
  onCancel,
  clickHTML,
  isOpenPopup = true,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <div>
      <div
        onClick={() => {
          if (isOpenPopup) {
            setOpen(true);
          }
        }}
      >
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="xs"
      >
        <div style={{ display: "flex", borderBottom: "1px solid #ccc" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "3rem",
              margin: "20px",
              color: "red",
            }}
          >
            <ImWarning />
          </div>
          <div>
            <DialogTitle
              style={{ cursor: "move", fontSize: "16px" }}
              id="draggable-dialog-title"
            >
              {title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ fontSize: "13px" }}>
                {content}
              </DialogContentText>
            </DialogContent>
          </div>
        </div>

        <DialogActions>
          <FormikController
            name="Confirm"
            control="buttons"
            onClick={handleConfirm}
            className="buttonSelected fixedWidth"
            fullWidth="false"
          />
          <FormikController
            name="Cancel"
            control="buttons"
            onClick={onCancel || handleClose}
            className="buttonUnselected fixedWidth"
            fullWidth="false"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(ConfirmPopUp);

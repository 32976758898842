import React, { useEffect, useRef, useState } from "react";
import "../../CSS/Dashboard.css";
// import Alert from "../../components/Alert/Alerts";
// import ConfirmPopUp from "../../components/ConfirmPopUp/ConfirmPopUp.js";
// import Invoice from "../../components/Invoice/Invoice";
import ClientCarePlan from "../../components/FormsInvoice/ClientCarePlan/ClintCarePlan";
// import CompetencyChecklist from "../../components/FormsInvoice/CompetencyChecklist/CompetencyChecklist";
// import MedicationRecord from "../../components/FormsInvoice/MedicationRecord/MedicationRecord";
// import ThreeMonthAssessmentForm from "../../components/FormsInvoice/ThreeMonthAssessmentForm/ThreeMonthAssessmentForm";
// import CaregiverTimeAndActivityRecord from "../../components/FormsInvoice/CaregiverTimeAndActivityRecord/CaregiverTimeAndActivityRecord";
// // import NursingAssessment from "../../components/FormsInvoice/NursingAssessment/NursingAssessment";
// import Loading from "../../components/Loading/Loading.js";
import AgencyStore from "../../Store/AgencyStore.js";

import PageTitle from "../PageTitle.js";
import Icon from "../../components/Icons/Icon.js";
import LeadsChart from "./Dashboard/LeadsChart.js";
import PieChartAdminEmployee from "./Dashboard/PieChartAdminEmployee.js";
import PieChartCordinator from "./Dashboard/PieChartCordinator.js";
import EmployeeChartNurse from "./Dashboard/EmployeeChartNurse.js";
import EmployeeChartCareAid from "./Dashboard/EmployeeChartCareAid.js";
import PieChartClient from "./Dashboard/PieChartClient.js";
import Notification from "./Dashboard/Notification.js";
// import PdfTableList from "./PdfViewer/PdfTableList.js";
// import PDF from "../../Agency/Pages/project_document.pdf";

const Dashboard = React.memo(() => {
  const customAlert = useRef();

  useEffect(() => {
    // Set the customAlert reference in the AgencyStore when the component mounts
    AgencyStore.setCustomAlert(customAlert.current);
  }, []);

  // const handleClick = () => {
  //   // Trigger a success alert
  //   AgencyStore.showAlert("warning", "Report has been submitted!!");
  // };

  // const handleDeleteClick = () => {
  //   // setIsLoading(true);
  //   AgencyStore.setIsLoading(true);
  //   // Simulate an asynchronous delete operation
  //   // setTimeout(() => {
  //   //   setIsLoading(false);
  //   //   // Add your actual delete logic here
  //   // }); // Simulating a 2-second delete operation, replace this with your actual code
  // };

  // function downloadPDF() {
  //   const link = document.createElement("a");
  //   link.href = PDF;
  //   link.download = "project_document.pdf";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle="Dashboard"
          subHeaderTitle=" Navigation/Dashboard"
        />
      </div>
      <div className="pageMainContainer">
        {/* <div className="dashboardGridContainer">
          <PieChartClient />
          <Notification />
        </div>
        <div className="dashboardGridContainer">
          <div className="dashboardChartContainer">
            <PieChartAdminEmployee />
            <PieChartCordinator />
          </div>

          <LeadsChart />
        </div>
        <div className="dashboardFullWidthContainer">
          <EmployeeChartNurse />
        </div>
        <div className="dashboardFullWidthContainer">
          <EmployeeChartCareAid />
        </div> */}

        {/* <Test /> */}
        {/* <PdfTableList /> */}
        {/* <button onClick={handlePdf}>click</button> */}
        {/* <ClientCarePlan /> */}
        {/* <NursingAssessment /> */}
        {/* <CaregiverTimeAndActivityRecord /> */}
        {/* <ThreeMonthAssessmentForm />
      <Invoice /> */}

        {/*
      <ConfirmPopUp />

      
     
     
    */}
        {/* <MedicationRecord /> */}
        {/* <CompetencyChecklist />
      <NursingAssessment /> */}
        {/* <button onClick={handleDeleteClick}>Delete</button> */}
        {/* {AgencyStore.snackBarComponent()} */}

        {/* <Loading /> */}

        {/* <button onClick={downloadPDF} style={{ color: "black" }}>
        Download full project document
      </button>
      <button onClick={openPDF}>Open PDF</button> */}
      </div>
    </div>
  );
});

export default Dashboard;

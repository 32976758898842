import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import React from "react";
import PdfFooter from "../PdfViewer/PdfFooter";
import { TableRow } from "../PdfViewer/PDFUtils";

import { styles } from "../PdfViewer/CommonUtilsPdf";

const HomeSafetyPdf = ({ dataList }) => {
  const SafetyTitle = () => (
    <View style={styles.safetyTitle}>
      <Text style={styles.heading}>Home Safety Assessment *</Text>
    </View>
  );
  const Details = () => (
    <View style={styles.detailContainer}>
      <View style={styles.detailWrapper}>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>Client Name:</Text>
          <Text>{dataList.clientName}</Text>
        </View>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>MR#:</Text>
          <Text>{dataList.mr}</Text>
        </View>
      </View>
      <View style={styles.detailWrapper}>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>Address:</Text>
          <Text>{dataList.clientAddress}</Text>
        </View>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>Client Lives With:</Text>
          <Text>{dataList.clientLivesWith}</Text>
        </View>
      </View>
      <View style={styles.detailWrapper}>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>Evaluation Completed By:</Text>
          <Text>{dataList.evalCompletedBy}</Text>
        </View>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>Date:</Text>
          <Text>{dataList.manualDate}</Text>
        </View>
      </View>
    </View>
  );

  const Recommendation = () => (
    <View style={styles.finalContainer}>
      <View style={styles.recommendationTitle}>
        <Text>
          Recommendations:CareGiver Aide to review copy of the Home Safety
          Assessment on Information Provide. Revise as appropriate
        </Text>
      </View>
      <View style={styles.recommendationTitle}>
        <Text>
          As of the date of this evaluation, I attest that this home is safe
          environment for nursing care.
        </Text>
      </View>
      <View style={styles.detailWrapper}>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>RN Signature:</Text>
          {dataList.rnSignedLink == null ? (
            ""
          ) : (
            <Image src={dataList.rnSignedLink} style={styles.image} />
          )}
        </View>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>Date:</Text>
          <Text>{dataList.signatureDate}</Text>
        </View>
      </View>
    </View>
  );

  const TableHomeSafety = () => (
    <View style={styles.tableWrapper}>
      <View style={styles.theader} fixed>
        <View style={styles.tableTitle}>
          <Text style={styles.tableItem}>Item No</Text>
        </View>
        <View style={styles.tableItemDescription}>
          <Text style={styles.tableItem}>Description</Text>
        </View>
        <View style={styles.tableTitle}>
          <Text style={styles.tableItem}>Yes</Text>
        </View>
        <View style={styles.tableTitle}>
          <Text style={styles.tableItem}>No</Text>
        </View>
        <View style={styles.tableTitle}>
          <Text style={styles.tableItem}>N/A</Text>
        </View>
      </View>
      <TableRow category="ENVIRONMENT" items={dataList.environment} />
      <TableRow category="FIRE/ELECTRICAL" items={dataList.fireElectrical} />
      <TableRow category="BATHROOM SAFETY" items={dataList.bathroomSafety} />
      <TableRow category="MEDICATION USE" items={dataList.medicationUse} />
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <SafetyTitle />
        <Details />
        <TableHomeSafety />
        <Recommendation />
        <PdfFooter />
      </Page>
    </Document>
  );
};

export default HomeSafetyPdf;

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "../../../CSS/Profile.css";
import CareGiverStore from "../../../Store/CareGiverStore";
import NurseClientView from "./NurseClientView";
import { useParams } from "react-router-dom";

import DocViewerList from "../DocViewerList/DocViewerList";
import NurseSecurity from "./NurseSecurity";

import ChangeLog from "../LogList/ChangeLog";
import GlobelStore from "../../../Store/GlobelStore";
import AddNurse from "./AddNurse";

import ClientStore from "../../../Store/ClientStore";
import NurseSchedule from "./NurseSchedule";
import CalenderStore from "../../../Store/CalenderStore";
import PageTitle from "../../PageTitle";

import Icon from "../../../components/Icons/Icon";
import EmpSignature from "../CommonPage/EmpSignature";

const NurseViewPage = observer(() => {
  const [value, setValue] = React.useState("1");
  const { employeeId } = useParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (employeeId) {
      CareGiverStore.getEmployeeProfile(employeeId);
      CareGiverStore.getConfidentialDoc(employeeId);
      CareGiverStore.getClientList();
      ClientStore.getEmployeeClientRelation(employeeId);

      CalenderStore.getSpecificEmployeeCalendar(employeeId);
      GlobelStore.getChangeLogs(employeeId);
      CareGiverStore.getEmpSignature(employeeId);
    }
  }, [employeeId]);

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle="Nurse"
          subHeaderTitle="  Employee/Nurse/Profile"
        />
      </div>

      <div className="profileMainContainer">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              className="profileTabsMenu"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
              >
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="profile" className="profileEeachMenuIcon" />
                  }
                  label="Profile"
                  value="1"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="signature"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Signature"
                  value="9"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Government Document"
                  value="7"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Confidential Document"
                  value="2"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="document"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Application & Hiring Document"
                  value="3"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="log" className="profileEeachMenuIcon" />
                  }
                  label="Logs"
                  value="8"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon iconName="client" className="profileEeachMenuIcon" />
                  }
                  label="Client"
                  value="4"
                />
                <Tab
                  className="profileEachMenu"
                  icon={
                    <Icon
                      iconName="schedule"
                      className="profileEeachMenuIcon"
                    />
                  }
                  label="Schedule"
                  value="5"
                />{" "}
                {GlobelStore.checkUserRole("AccountAndAccess") && (
                  <Tab
                    className="profileEachMenu"
                    icon={
                      <Icon
                        iconName="account"
                        className="profileEeachMenuIcon"
                      />
                    }
                    label="Account"
                    value="6"
                  />
                )}
              </TabList>
            </Box>
            <TabPanel className="profileEachTabPanel" value="1">
              <AddNurse />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="9">
              <EmpSignature />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="2">
              <DocViewerList confidentialResultSet="confidentialResultSet" />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="7">
              <DocViewerList
                govResultSet="govResultSet"
                immigrationStatus={
                  CareGiverStore?.careGiverProfileData?.immigrationStatus
                }
              />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="3">
              <DocViewerList applicationResumeAndHireResultSet="applicationResumeAndHireResultSet" />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="8">
              <ChangeLog employeeCategory="RN" />
            </TabPanel>

            <TabPanel className="profileEachTabPanel" value="4">
              <NurseClientView employeeCategory="Client" />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="5">
              <NurseSchedule />
            </TabPanel>
            {GlobelStore.checkUserRole("AccountAndAccess") && (
              <TabPanel className="profileEachTabPanel" value="6">
                <NurseSecurity />
              </TabPanel>
            )}
          </TabContext>
        </Box>
      </div>
    </div>
  );
});

export default NurseViewPage;

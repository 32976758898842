import React, { useEffect, useMemo, useState } from "react";
import { AddCarePlanService } from "./AddCarePlanService";
import SearchBox from "../../../components/Search/SearchBox";
import useSearchData from "../../../components/useSearchData";
import Icon from "../../../components/Icons/Icon";

import { createColumnDef, useOnGridReady } from "../../../Utils/AgGridUtils";
import { useParams } from "react-router-dom";
import ClientStore from "../../../Store/ClientStore";
import CareGiverStore from "../../../Store/CareGiverStore";
import { centerStyles, leftStyles } from "../../../Constants/Constants";
import FormikController from "../../../components/FormikController/FormikController";

import CGPOSPdf from "./CGPOSPdf";
import { openPdf } from "../PdfViewer/CommonUtilsPdf";

const CareGiverPlanOfServiceViewPage = () => {
  const [show, setShow] = useState(false);
  const [openEditButton, setOpenEditButton] = useState("save");
  const [onGridReady] = useOnGridReady(ClientStore, "safetyAgGrid");
  const { clientId } = useParams();
  const [posList, setPosList] = useState([]);
  const [editData, setEditData] = useState([]);
  const [updateChange, setUpdateChange] = useState(false);
  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setUpdateChange(true);
    setShow(false);
    setOpenEditButton("save");
  };
  const [handleSearchChange] = useSearchData();

  const columnDefs = useMemo(
    () => [
      createColumnDef("clientName", "Client Name", leftStyles, "", "", 90),
      createColumnDef("rnName", "Nurse Name", leftStyles, "", "", 90),
      createColumnDef(
        "caregiverName",
        "CareGiver Name",
        leftStyles,
        "",
        "",
        90
      ),

      createColumnDef("visitType", "Visit Type", leftStyles, "", "", 70),
      createColumnDef("systemEntryDate", "Upload Date", leftStyles, "", "", 80),
      createColumnDef(
        "lastUpdatedDate",
        "Modified Date",
        leftStyles,
        "",
        "",
        80
      ),

      {
        headerName: "Action",
        sortable: false,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        width: 100,
        cellRenderer: (params) => {
          const handleEditButton = () => {
            setEditData(params.data);
            setOpenEditButton("update");
            handleOpen();
          };
          const handleDownloadButton = () => {
            setEditData(params.data);
            openPdf(<CGPOSPdf dataList={params.data} />);
          };
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div className="manageClick" onClick={() => handleEditButton()}>
                Edit
              </div>
              <div
                className="docViewText"
                onClick={() => handleDownloadButton()}
              >
                View
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    const fetchposList = async () => {
      try {
        await ClientStore.getCaregiverPOS(clientId);
        getRecordList();
      } catch (error) {
        console.error("Error fetching care giver plan of service list:", error);
      }
    };
    fetchposList();
  }, [updateChange]);

  const getRecordList = () => {
    const data = [];
    Object.entries(ClientStore.carePosRecordList).forEach(
      ([category, items]) => {
        if (items.length > 0) {
          items.forEach((eachItem) => {
            data.push(eachItem);
          });
        }
      }
    );
    setPosList(data);
    CareGiverStore.setTableHeight(data?.length);
  };

  return (
    <>
      <div className="homeSafetyWrapper">
        {show ? (
          <>
            <AddCarePlanService
              type={openEditButton}
              editData={editData}
              handleClose={handleClose}
              handleOpen={handleOpen}
            />
            {/* <PDFViewer width="1000" height="650" className="app">
              <CGPOSPdf dataList={editData} />
            </PDFViewer>{" "} */}
          </>
        ) : (
          <>
            <div className="actionButtonSearchMainContainer">
              <div className="searchMainCotainer">
                <div className="searchContainer">
                  <SearchBox
                    handleChange={(e) => {
                      handleSearchChange(e, "DocList");
                    }}
                  />
                </div>
                <div className="underlineClick" onClick={handleOpen}>
                  <Icon iconName="circleAdd" />
                  Add CG Plan Of Service
                </div>
              </div>
            </div>
            <FormikController
              rowData={posList}
              control="agGrid"
              columnDefs={columnDefs}
              onGridReady={onGridReady}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CareGiverPlanOfServiceViewPage;

export const home_safety = {
  environment: [
    {
      id: 1,
      index: 1,
      description: "Safe and adequate food and water supplies",
    },
    {
      id: 2,
      index: 2,
      description: "Stove and means for refrigerator present",
    },
    {
      id: 3,
      index: 3,
      description: "Adequate heat and ventilation",
    },
    {
      id: 4,
      index: 4,
      description: "Free from infestation",
    },
    {
      id: 5,
      index: 5,
      description:
        "Pathways free of obstacles such as loose rugs, furniture, etc.",
    },
    {
      id: 6,
      index: 6,
      description: "Clean area exists in which to store medical supplies",
    },
    {
      id: 7,
      index: 7,
      description: "Is cautious with heating pads",
    },
    {
      id: 8,
      index: 8,
      description: "Has a working smoke detector",
    },
    {
      id: 9,
      index: 9,
      description: "If uses oxygen, appropriate signs posted",
    },
  ],
  fireElectrical: [
    {
      id: 1,
      index: 10,
      description: "Fire exists available;warning devices installed",
    },
    {
      id: 2,
      index: 11,
      description:
        "No overuse of extension cords/adequate electrical outlets available",
    },
    {
      id: 3,
      index: 13,
      description: "Turns off oven and stove burners",
    },
    {
      id: 4,
      index: 14,
      description: "Emergency telephone numbers posted by phone",
    },
    {
      id: 5,
      index: 15,
      description: "Turns pot handles to back of stove",
    },
    {
      id: 6,
      index: 16,
      description: "Uses space heaters cautiously",
    },
    {
      id: 7,
      index: 17,
      description: "Does not smoke in bed",
    },
    {
      id: 8,
      index: 18,
      description: "Oxygen precautions used",
    },
  ],

  bathroomSafety: [
    {
      id: 1,
      index: 19,
      description: "No throws rugs",
    },
    {
      id: 2,
      index: 20,
      description: "Safety bars present and in good condition",
    },
    {
      id: 3,
      index: 21,
      description: "Lighting is adequate",
    },
    {
      id: 4,
      index: 22,
      description: "Shower chair is sturdy and in good working condition",
    },
  ],
  medicationUse: [
    {
      id: 1,
      index: 23,
      description: "Keeps all medications in original bottle or med box",
    },
    {
      id: 2,
      index: 24,
      description: "Has a medication schedule",
    },
    {
      id: 3,
      index: 25,
      description: "Home safety Instructions Given",
    },
  ],
};

export const cgpos = [
  {
    task: "Bathing",
    comment: "",
    yesNo: false,
  },
  {
    task: "Care of Teeth",
    comment: "",
    yesNo: false,
  },
  {
    task: "Care of skin",
    comment: "",
    yesNo: false,
  },
  {
    task: "Care of hair",
    comment: "",
    yesNo: false,
  },

  {
    task: "Dressing",
    comment: "",
    yesNo: false,
  },

  {
    task: "Meal Preparation",
    comment: "",
    yesNo: false,
  },

  {
    task: "Eating",
    comment: "",
    yesNo: false,
  },

  {
    task: "Toileting",
    comment: "",
    yesNo: false,
  },

  {
    task: "Transferring",
    comment: "",
    yesNo: false,
  },

  {
    task: "Ambulation",
    comment: "",
    yesNo: false,
  },

  {
    task: "Straightening Area",
    comment: "",
    yesNo: false,
  },

  {
    task: "Changing Bed",
    comment: "",
    yesNo: false,
  },

  {
    task: "Food Shopping",
    comment: "",
    yesNo: false,
  },

  {
    task: "Escort to Med Service",
    comment: "",
    yesNo: false,
  },

  {
    task: "Monitoring for safety",
    comment: "",
    yesNo: false,
  },

  {
    task: "Infection Control",
    comment: "",
    yesNo: false,
  },

  {
    task: "Light Housekeeping",
    comment: "",
    yesNo: false,
  },

  {
    task: "Medication*",
    comment: "",
    yesNo: false,
  },

  {
    task: "Other:",
    comment: "",
    yesNo: false,
  },
];

export const careGiverEvalList = [
  {
    evaluatedArea: "Bathing",
    satisfactory: false,
    comment: "",
  },
  {
    evaluatedArea: "Care of Teeth",
    satisfactory: false,
    comment: "",
  },
  {
    evaluatedArea: "Care of skin",
    satisfactory: false,
    comment: "",
  },
  {
    evaluatedArea: "Care of hair",
    satisfactory: false,
    comment: "",
  },
  { evaluatedArea: "Dressing", satisfactory: false, comment: "" },
  { evaluatedArea: "Meal Preparation", satisfactory: false, comment: "" },
  { evaluatedArea: "Eating ", satisfactory: false, comment: "" },
  { evaluatedArea: "Toileting", satisfactory: false, comment: "" },
  { evaluatedArea: "Transferring", satisfactory: false, comment: "" },

  {
    evaluatedArea: "Ambulation",
    satisfactory: false,
    comment: "",
  },
  {
    evaluatedArea: "Straightening Area",
    satisfactory: false,
    comment: "",
  },
  { evaluatedArea: "Changing Bed", satisfactory: false, comment: "" },
  { evaluatedArea: "Food shopping", satisfactory: false, comment: "" },
  { evaluatedArea: "Escort to Med Service ", satisfactory: false, comment: "" },
  { evaluatedArea: "Monitoring for safety", satisfactory: false, comment: "" },
  { evaluatedArea: "Infection Control", satisfactory: false, comment: "" },
  { evaluatedArea: "Light Housekeeping", satisfactory: false, comment: "" },
  { evaluatedArea: "Medication* ", satisfactory: false, comment: "" },
  { evaluatedArea: "Other: ", satisfactory: false, comment: "" },
];

import React, { useState } from "react";
import AddHomeSafety from "./AddHomeSafety";
import Icon from "../../../components/Icons/Icon";
import SearchBox from "../../../components/Search/SearchBox";
import HomeSafetyList from "./HomeSafetyList";

import useSearchData from "../../../components/useSearchData";

const HomeSafetyViewPage = () => {
  const [show, setShow] = useState(false);
  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const [handleSearchChange] = useSearchData();

  return (
    <>
      <div className="homeSafetyWrapper">
        {show ? (
          <AddHomeSafety
            type="save"
            handleClose={handleClose}
            handleOpen={handleOpen}
          />
        ) : (
          <>
            {/* <div className="medicationContainer">
              <div className="underlineClick" onClick={handleOpen}>
                <Icon iconName="circleAdd" />
                Add Home Safety Records
              </div>
            </div> */}
            <div className="actionButtonSearchMainContainer">
              <div className="searchMainCotainer">
                <div className="searchContainer">
                  <SearchBox
                    handleChange={(e) => {
                      handleSearchChange(e, "DocList");
                    }}
                  />
                </div>
                <div className="underlineClick" onClick={handleOpen}>
                  <Icon iconName="circleAdd" />
                  Add Home Safety Records
                </div>
              </div>
            </div>
            <HomeSafetyList />
          </>
        )}
      </div>
    </>
  );
};

export default HomeSafetyViewPage;

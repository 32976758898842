import { nurseVisitType } from "../Constants/Constants";

const serverUrl = "https://gbhcsoftware.com:8080/api";
// const serverUrl = "http://44.217.55.229:8080/api";

export const ServerURL = {
  LOGIN: serverUrl + "/login",
  GET_BRANCH: serverUrl + "/getBranches",
  CHECK_TOKEN: serverUrl + "/checkTokenValidity",
  GET_TOKEN: { Authorization: localStorage.getItem("loginToken") },
  LOGOUT: serverUrl + "/logout",
};

export const CareGiverURL = {
  Add_EMLOYEE: serverUrl + "/addEmployee",
  GET_EMLOYEE_LIST: serverUrl + "/getEmployeeList",
  SAVE_GOVERNMENTDOC: serverUrl + "/uploadEmployeeGovDocs",
  SAVE_CONFIDENTIALTDOC: serverUrl + "/uploadEmployeeConfidentialDocs",
  GET_EMPLOYEEDOCS: serverUrl + "/getEmployeeDocs",
  GET_EMPLOYEE_DOCS: serverUrl + "/employeeUser/getEmployeeDocs",

  GET_EMPLOYEEPROFILE: serverUrl + "/getEmployeeProfile",
  SAVE_SINGLEFIle: serverUrl + "/uploadEmployeeDocument",
  UPDATE_EmployeeProfile: serverUrl + "/updateEmployeeProfile",
  DELETE_EMPLOYEEDOC: serverUrl + "/deleteEmployeeDocument",
  CREATE_CAREGIVER_ACCOUNT:
    serverUrl + "/registration/createEmployeeUserAccount",
  UPDATE_EMPLOYEEROLES: serverUrl + "/registration/updateEmployeeRoles",
  ENABLEDISABLE_EMPLOYEEACCOUNT:
    serverUrl + "/registration/enableDisableEmployeeAccount",

  GET_COC_ASSOCIATED_CLIENT: serverUrl + "/getCocAssociatedClients",
  UPDATE_APPROVAL_EMP_DOC: serverUrl + "/updateApprovalForEmpDoc",

  DELETE_EMP: serverUrl + "/deleteEmployee",

  GET_EMP_CLOCKINOUT_DTL: serverUrl + "/employee/getEmpSpecificClockInDetails",
  ADD_EMP_CLOCKINOUT: serverUrl + "/employee/saveClockInOut",
  UPDATE_EMP_CLOCKINOUT: serverUrl + "/employee/updateClockInDetail",
  DELETE_EMP_CLOCKINOUT: serverUrl + "/employee/delClockInDetails",
  DOWNLOAD_WORKHRS_DTL: serverUrl + "/employee/downloadClockInDetails",

  ADD_EMP_SIGNATURE: serverUrl + "/addEmpSignature",
  GET_EMP_SIGNATURE: serverUrl + "/getEmpSignature",
};

export const ClientURL = {
  SAVE_CLIENT_PROFILE: serverUrl + "/client/saveClientProfile",
  GET_CLIENT_PROFILE_LIST: serverUrl + "/client/getClientProfileList",
  GET_LEADS_PROFILE_LIST: serverUrl + "/client/getLeadProfileList",
  UPDATE_CLIENT_PROFILE: serverUrl + "/client/updateClientProfile",
  GET_CLIENT_PROFILE: serverUrl + "/client/getClientProfile",

  SAVE_DOCTOR_INFO: serverUrl + "/client/saveDoctorInfo",
  UPDATE_DOCTOR_INFO: serverUrl + "/client/updateDoctorInfo",
  GET_DOCTOR_INFO: serverUrl + "/client/getDoctorInfo",
  DELETE_DOCTOR_INFO: serverUrl + "/client/deleteDoctorInfo",

  GET_Client_Document: serverUrl + "/client/getClientDocuments",
  UPLOAD_CLIENT_DOCUMENT: serverUrl + "/client/uploadClientDocument",
  DELETE_CLIENT_DOCUMENT: serverUrl + "/client/deleteClientDocument",

  SAVE_DOCTOR_CASENOTE: serverUrl + "/client/saveDoctorCaseNote",
  UPDATE_DOCTOR_CASENOTE: serverUrl + "/client/updateDoctorCaseNote",
  GET_DOCTOR_CASENOTE: serverUrl + "/client/getDoctorCaseNotes",

  SAVE_CLIENT_CASENOTE: serverUrl + "/client/saveClientProfileCaseNote",
  UPDATE_CLIENT_CASENOTE: serverUrl + "/client/updateClientProfileCaseNote",
  GET_CLIENT_CASENOTE: serverUrl + "/client/getClientProfileCaseNotes",

  GET_POS_DOC: serverUrl + "/client/getPosDoc",
  UPLOAD_POS_DOC: serverUrl + "/client/uploadPOSDoc",
  DELETE_POS_DOC: serverUrl + "/client/deletePosDoc",

  GET_CLIENT_RELATION: serverUrl + "/getEmployeeClientRelation",
  DELETE_CLIENT_RELATION: serverUrl + "/deleteEmployeeClientRelation",
  SET_CLIENT_RELATION: serverUrl + "/setEmployeeClientRelation",
  GET_CLIENT_Emp_RELATION: serverUrl + "/getClientEmpRelation",

  CREATE_CLIENT_ACCOUNT: serverUrl + "/registration/createClientAccount",
  ENABLEDISABLE_CLIENT_ACCOUNT:
    serverUrl + "/registration/enableDisableClientAccount",

  GET_NURSINGASSESSMENT_LIST_BY_CLIENT:
    serverUrl + "/client/getNursingAssessmentListByClient/",

  GET_NURSINGASSESSMENT_DOCUMENTS:
    serverUrl + "/client/getNursingAssessmentDocuments",
  UPLOAD_NURSINGASSESSMENT_DOCUMENT:
    serverUrl + "/client/uploadNursingAssessmentDoc",
  DELETE_NURSINGASSESSMENT_DOCUMENT:
    serverUrl + "/client/deleteNursingAssessmentDoc",

  SAVE_CLIENT_MEDICATION_RECORD:
    serverUrl + "/employeeUser/saveClientMedicationList",

  DELETE_CLIENT: serverUrl + "/client/deleteClientProfile",

  ADD_CLIENT_SIGNATURE: serverUrl + "/client/addClientSignature",
  GET_CLIENT_SIGNATURE: serverUrl + "/client/getClientSignature",

  SAVE_HOME_SAFETY: serverUrl + "/client/saveHomeSafety",
  GET_HOME_SAFETY_LIST: serverUrl + "/client/getHomeSafetyList",
  UPDATE_HOME_SAFETY: serverUrl + "/client/updateHomeSafety",

  SAVE_CLIENT_DIAGNOSIS: serverUrl + "/client/saveClientDiagnosis",
  UPDATE_CLIENT_DIAGNOSIS: serverUrl + "/client/updateClientDiagnosis",
  GET_CLIENT_DIAGNOSIS: serverUrl + "/client/getClientDiagnosisList",
  DELETE_CLIENT_DIAGNOSIS: serverUrl + "/client/deleteClientDiagnosis",

  SAVE_CARE_POS: serverUrl + "/client/saveCGPlanOfService",
  GET_CareGiver_POS_LIST: serverUrl + "/client/getCGPlanOfServiceList",
  UPDATE_CAREGIVER_POS: serverUrl + "/client/updateCGPlanOfService ",
  GET_CareGiver_POS_ID: serverUrl + "/client/getCGPlanOfService",

  SAVE_CAREGIVER_EVAL_FORM: serverUrl + "/client/saveCareGiverEvaluation",
};

export const EmpApplicationResumeHiringDoc = {
  EMP_APPLCATION_RESUME_HIRING_DOC:
    serverUrl + "/uploadEmpApplicationResumeHiringDoc",
};

export const CalenderUrl = {
  SET_EMPLOYEE_CALENDER: serverUrl + "/calendar/setEmployeeCalendar",
  GET_EMPLOYEE_CALENDER: serverUrl + "/calendar/getEmployeeCalendar",
  GET_SPECIFIC_EMPLOYEE_CALENDER:
    serverUrl + "/calendar/getSpecificEmployeeCalendar",
  GET_SPECIFIC_CLIENT_CALENDER: serverUrl + "/calendar/getClientCalendar",
  UPDATE_EMPLOYEE_CALENDER: serverUrl + "/calendar/updateEmployeeCalendar",
  DELETE_EMPLOYEE_CALENDER: serverUrl + "/calendar/deleteEmployeeCalendar",
  CANCEL_EMPLOYEE_CALENDER: serverUrl + "/calendar/cancelEmployeeCalendar",
};

export const NotificationURL = {
  GET_UNSEEN_NOTIFICATION_ADMIN:
    serverUrl + "/getEmpClientUnseenNotificationCount",

  GET_EMPLOYEE_NOTIFICATION: serverUrl + "/getEmployeeNotification",
  SET_EMPLOYEE_NOTIFICATION_VIEW: serverUrl + "/setEmpNotificationViewedStatus",

  GET_CLIENT_NOTIFICATION: serverUrl + "/client/getClientNotification",
  SET_CLIENT_NOTIFICATION_VIEW:
    serverUrl + "/client/setClientNotificationViewedStatus",
};

export const GlobelURL = {
  get_AvailableRoles: serverUrl + "/registration/getAvailableRoles",
};

export const ChangePassword = {
  Change_Password: serverUrl + "/changeCredentials",
};
export const FORGET_PASSWORD = {
  Forget_Password: serverUrl + "/forgetPass",
  Validate_Forget_Password: serverUrl + "/validateForgetPassCode",
  Change_Forget_Password: serverUrl + "/changeForgetPassword",
};

export const LogURL = {
  GET_LOG_LIST: serverUrl + "/getChangeLogs",
  Employee_Change_Log: serverUrl + "/employeeChangeLog/saveChangeLog",
  Client_Change_Log: serverUrl + "/manualClientChangeLog/saveManualChangeLog",
  Manual_Employee_Change_Log:
    serverUrl + "/employeeChangeLog/getEmployeeManualChangeLogs",

  Manual_Client_Change_Log:
    serverUrl + "/manualClientChangeLog/getManualChangeLog",
};

export const NurseAndCareGiverURL = {
  GET_PROFILE: serverUrl + "/employeeUser/getProfile",
  GET_EMPLOYEE_DOCS: serverUrl + "/employeeUser/getEmployeeDocs",
  SAVE_SINGLEFIle: serverUrl + "/employeeUser/uploadEmployeeDocument",
  EMP_CLIENT_RELATION: serverUrl + "/employeeUser/getEmployeeClientRelation",
  GET_CLIENT_PROFILE: serverUrl + "/employeeUser/getClientProfile",
  GET_NURSINGASSESSMENT_DOCUMENTS:
    serverUrl + "/employeeUser/getNursingAssessmentDocuments",
  UPLOAD_NURSINGASSESSMENT_DOCUMENT:
    serverUrl + "/employeeUser/uploadNursingAssessmentDoc",
  GET_EMPLOYEE_NOTIFICATION_EMPLOYEE_USER:
    serverUrl + "/employeeUser/getNotification",
  SET_NOTIFICATION_VIEW: serverUrl + "/employeeUser/setNotificationStatus",
  GET_CALENDER: serverUrl + "/employeeUser/getCalendar",
  GET_UNSEEN_NOTIFICATION:
    serverUrl + "/employeeUser/getUnseenNotificationCount",
};

export const ManualEmailSmsURL = {
  SEND_EMAIL_SMS_EMPLOYEE: serverUrl + "/manualNoti/sendNotiToEmp",
  SEND_EMAIL_SMS_CLIENT: serverUrl + "/manualNoti/sendNotiToClient",
  GET_EMAIL_SMS_EMPLOYEE: serverUrl + "/manualNoti/getEmpManualNoti",
  GET_EMAIL_SMS_CLIENT: serverUrl + "/manualNoti/getClientManualNoti",
  GET_EMP_SINGLE_NOTI_DETAILS:
    serverUrl + "/manualNoti/getEmpSingleNotiDetails/",
  GET_CLIENT_SINGLE_NOTI_DETAILS:
    serverUrl + "/manualNoti/getClientSingleNotiDetails/",
};

export const FileTypeMap = [
  { displayName: "ID", uniqueName: "idCard" },
  { displayName: "Green Card", uniqueName: "greenCard" },
  { displayName: "Social Security", uniqueName: "socialSecurity" },
  { displayName: "I9", uniqueName: "i9" },
  { displayName: "W4", uniqueName: "w4" },
  { displayName: "Privacy Act Statement", uniqueName: "privacyActStatement" },
  {
    displayName: "Noncriminal justice applicants’ privacy rights",
    uniqueName: "noncriminalJustice",
  },
  { displayName: "Physical & PPD", uniqueName: "physicalAndPpd" },
  { displayName: "CPR & First Aide", uniqueName: "cprAndFirstAide" },
  { displayName: "Background Check", uniqueName: "backgrounCheck" },
  { displayName: "Direct Deposit", uniqueName: "directDeposit" },
  { displayName: "OIG Search", uniqueName: "oigSearch" },
  { displayName: "Sex Offender", uniqueName: "sexOffender" },
  { displayName: "Health Statement", uniqueName: "healthStatement" },
  {
    displayName: "Hepatitis Vaccine Requirement",
    uniqueName: "hepatitisVaccineRequirement",
  },
  { displayName: "TB Targeted", uniqueName: "tbTargeted" },
  {
    displayName: "Employment Authorization Card",
    uniqueName: "employmentAuthCard",
  },
  {
    displayName: "Hiring Doc",
    uniqueName: "hiringDoc",
  },
  {
    displayName: "Application",
    uniqueName: "application",
  },
  {
    displayName: "Contract",
    uniqueName: "contract",
  },
  {
    displayName: "Resume",
    uniqueName: "resume",
  },
  {
    displayName: "Background Check",
    uniqueName: "backgroundCheck",
  },
  {
    displayName: "Client Info Package",
    uniqueName: "clientInfoPackage",
  },
  {
    displayName: "Wavier",
    uniqueName: "wavier",
  },
  {
    displayName: "ID Card",
    uniqueName: "idCard",
  },
  {
    displayName: "MA",
    uniqueName: "mA",
  },
  {
    displayName: "Nursing Assessment",
    uniqueName: "NURSING_ASSESSMENT",
  },
  {
    displayName: "Medication Records List",
    uniqueName: "MEDICATION_RECORDS_LIST",
  },
  {
    displayName: "Home Safety",
    uniqueName: "HOME_SAFETY",
  },
  {
    displayName: "Client Care Plan",
    uniqueName: "CLIENT_CARE_PLAN",
  },
  {
    displayName: "Caregiver Evaluation Form",
    uniqueName: "CAREGIVER_EVALUATION_FORM",
  },
  {
    displayName: "Caregiver Plan of Service",
    uniqueName: "CAREGIVER_PLAN_OF_SERVICE",
  },
  {
    displayName: "Competency Checklist Personal Care Aide",
    uniqueName: "COMPETENCY_CHECKLIST_PERSONAL_CARE_AIDE",
  },
  {
    displayName: "Participant Review And Caregiver Supervisory Visit",
    uniqueName: "PARTICIPANT_REVIEW_AND_CAREGIVER_SUPERVISORY_VISIT",
  },
  {
    displayName: "Physical",
    uniqueName: "physical",
  },
  {
    displayName: "PPD",
    uniqueName: "ppd",
  },
  {
    displayName: "Everify",
    uniqueName: "everify",
  },
  {
    displayName: "Criminal Record Release Auth Form",
    uniqueName: "criminalRecordReleaseAuthForm",
  },
  {
    displayName: "Intake Form",
    uniqueName: "intakeForm",
  },
];

export const getFileTypeId = (label) => {
  var matchingFileType;
  FileTypeMap.forEach((file) => {
    const key = file.displayName;
    if (key == label) {
      matchingFileType = file.uniqueName;
    }
  });

  return matchingFileType;
};

export const getVisitTypeName = (value) => {
  var matchingFileType;
  nurseVisitType.forEach((file) => {
    const key = file.value;
    if (key == value) {
      matchingFileType = file.label;
    }
  });

  return matchingFileType;
};

export const checkExpiryDate = [
  "idCard",
  "greenCard",
  "cprAndFirstAide",
  "employmentAuthCard",
];

import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { AiOutlineClose } from "react-icons/ai";
import { observer } from "mobx-react";
import { useFormik } from "formik";
import { YupValidation } from "../../../../Constants/ValidationConstants";
import * as Yup from "yup";
import FormikController from "../../../../components/FormikController/FormikController";
import { DialogActions, TextField } from "@mui/material";

import { CareGiverURL } from "../../../../Url/Url";
import axios from "axios";
import AgencyStore from "../../../../Store/AgencyStore";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DownloadClockInOutPopUp = ({
  onConfirm,
  clickHTML,
  type,
  selectedIds,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  const formatDateForFileName = () => {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}_${month}_${day}_${hours}_${minutes}_${seconds}_workDetails.xlsx`;
  };

  const formik = useFormik({
    initialValues: {
      fromDate: "",
    },

    validationSchema: Yup.object({
      fromDate: YupValidation.specificDayOnly,
    }),
    onSubmit: (values) => {
      downloadWorkHrsDetails(values);
    },
  });

  const downloadWorkHrsDetails = async (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      fromDate: formatDate(values.fromDate),
    };

    await axios
      .post(CareGiverURL.DOWNLOAD_WORKHRS_DTL, params, {
        responseType: "blob",
        headers: {
          Authorization: localStorage.getItem("loginToken"),
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          let fileName = formatDateForFileName();
          // Default name with .xlsx extension
          // const contentDisposition = res.headers["content-disposition"];
          // if (contentDisposition) {
          //   const matches = contentDisposition.match(/filename="(.+)"/);
          //   if (matches && matches[1]) {
          //     fileName = matches[1];
          //   }
          // }

          // Create the blob with the correct MIME type for Excel files
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // Create a download link and trigger it
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); // Set the correct file name with .xlsx extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Reset form and stop loading
          formik.resetForm();

          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "success",
            "Successfully Download Work Hours Details"
          );
        } else if (res?.data?.status === "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "error",
            "Failed to Download Work Hours Details"
          );
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to Download Work Hours Details");
      });
  };

  return (
    <div className="action-popup-container">
      <div className="action-trigger" onClick={handleClickOpen}>
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          id="draggable-dialog-title"
          className="DialogTitleContainer"
        >
          <div className="dialog-title-container">
            Download Clock In/Out
            <AiOutlineClose className="close-icon" onClick={handleClose} />
          </div>
        </DialogTitle>
        <div className="UCPUaction-popup-content ">
          <div className="marginTop clientDoctorCaseNoteInputContainer">
            <div className="formInputContainer">
              <FormikController
                name="date"
                error
                label="Date"
                type="date"
                control="textfield"
                values={formik.values.fromDate}
                {...formik.getFieldProps("fromDate")}
                touch={formik.touched.fromDate}
                errorname={formik.errors.fromDate}
              />
            </div>
          </div>
        </div>

        <DialogActions className="DialogActionsContainer">
          <div>
            <FormikController
              name="Download"
              control="buttons"
              onClick={() => {
                formik.handleSubmit();
              }}
              className="buttonSelectedGreen menuItemButton "
              fullWidth={false}
            />
          </div>
          <FormikController
            name="Cancel"
            control="buttons"
            onClick={handleClose}
            className="buttonUnselected fixedWidth"
            fullWidth="false"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(DownloadClockInOutPopUp);

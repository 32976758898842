import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import CareGiverStore from "../../../../Store/CareGiverStore";
import FormikController from "../../../../components/FormikController/FormikController";
import AddClockInOut from "./AddClockInOut";
import CareGiverClockInOutTable from "./CareGiverClockInOutTable";
import Icon from "../../../../components/Icons/Icon";
import AgencyStore from "../../../../Store/AgencyStore";
import { formatDate } from "../../../../Utils/Utils";

const CareGiverClockInOut = () => {
  const { employeeId } = useParams();

  const formik = useFormik({
    initialValues: {
      fromDate: formatDate(localStorage.getItem("fromDate")),
      toDate: formatDate(localStorage.getItem("toDate")),
    },
    validate: (values) => {
      const errors = {};
      localStorage.setItem("fromDate", values.fromDate);
      localStorage.setItem("toDate", values.toDate);
      if (new Date(values.fromDate) > new Date(values.toDate)) {
        // errors.fromDate = "! From Date should not be later than To Date";
        AgencyStore.showAlert(
          "error",
          "From Date should not be later than To Date"
        );
        return errors;
      }
    },
    onSubmit: (values) => {
      // Handle form submission if needed
    },
  });

  useEffect(() => {
    if (
      formik.values.fromDate &&
      formik.values.toDate &&
      new Date(formik.values.fromDate) <= new Date(formik.values.toDate)
    ) {
      CareGiverStore.getEmpClockInOutDetails(
        employeeId,
        formatDate(formik.values.fromDate),
        formatDate(formik.values.toDate)
      );
    }
  }, [formik.values.fromDate, formik.values.toDate, employeeId]);

  return (
    <div className="clockInOutContainer">
      <div className="searchPdfContainer">
        <div className="DateFromToConatiner ">
          <FormikController
            name="fromDate"
            error={formik.errors.fromDate}
            label="From Date"
            type="date"
            control="textfield"
            value={formik.values.fromDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FormikController
            name="toDate"
            error={formik.errors.toDate}
            label="To Date"
            type="date"
            control="textfield"
            value={formik.values.toDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        {/* {formik.errors.fromDate && (
          <div className="error">{formik.errors.fromDate}</div>
        )} */}
        <div className="changeLogBtnContainer">
          <AddClockInOut
            type="save"
            clickHTML={
              <div className="changeLogBtn underlineClick">
                <Icon iconName="circleAdd" />
                <span className="underlineClickTitle">Add Clock In Out</span>
              </div>
            }
          />
        </div>
      </div>
      <CareGiverClockInOutTable />
    </div>
  );
};

export default observer(CareGiverClockInOut);

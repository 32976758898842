import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";

import "../../../CSS/Client.css";

import ClientList from "./ClientList";

import EmployeeNotiEmailSmsList from "./EmployeeNotiEmailSmsList";
import ClientStore from "../../../Store/ClientStore";

const ClientMainPage = observer(() => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="clientDoctorMainContaner">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
              >
                <Tab className="profileEachMenu" label="Send" value="1" />
                <Tab
                  className="profileEachMenu"
                  label="Send History"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel className="profileEachTabPanel" value="1">
              <ClientList />
            </TabPanel>
            <TabPanel className="profileEachTabPanel" value="2">
              <EmployeeNotiEmailSmsList
                category="client"
                employeeName="Client"
                storeName={ClientStore}
                storeGrid="clientAgGrid"
              />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </>
  );
});

export default ClientMainPage;

import React from "react";
import { observer } from "mobx-react";
import "../../../CSS/CareGiver.css";
import FormikController from "../../../components/FormikController/FormikController";
import CareGiverStore from "../../../Store/CareGiverStore";
import CommunityOutReachCoordinatorTable from "./CommunityOutReachCoordinatorTable";
import PageTitle from "../../PageTitle";
import SearchBox from "../../../components/Search/SearchBox";
import useSearchData from "../../../components/useSearchData";
import Icon from "../../../components/Icons/Icon";
import { useNavigate } from "react-router-dom";

const CommunityOutReachCoordinator = observer(() => {
  const navigate = useNavigate();
  const [handleSearchChange, handleActiveCheckBox] = useSearchData();
  const handleAddCommunity = () => {
    CareGiverStore.setIsCareGiverProfile(false);
    CareGiverStore.setIsCareGiverSaved(false);
    CareGiverStore.setCareGiverDbId(null);
    navigate("/agency/addCommunityOutReachCoordinator");
  };

  return (
    <div>
      <div className="pageHeaderContainer">
        <PageTitle
          icon={<Icon iconName="menu" />}
          headerTitle=" Community Outreach Coordinator"
          subHeaderTitle="Employee/Community Outreach Coordinator"
        />

        <div>
          <div className="actionButonContainer">
            <FormikController
              name="Add CO. Coordinator"
              control="buttons"
              className="roundedButton menuItemButton addCareGiverButton addButton"
              startIcon={<Icon iconName="groupAdd" />}
              onClick={() => handleAddCommunity()}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="actionButtonSearchMainContainer">
          <div className="searchMainCotainer">
            <div className="searchContainer">
              <SearchBox
                handleChange={(e) => {
                  handleSearchChange(e, "CommunityCoordinator");
                }}
              />
            </div>
            <div className="activeAddButtonContainer">
              <div>
                <input
                  id="careGiver_active"
                  type="checkbox"
                  onChange={(e) => {
                    handleActiveCheckBox(e, "Active", "CommunityCoordinator");
                  }}
                />
                <span className="checkBoxTitle">Active</span>
              </div>
            </div>
          </div>
        </div>
        <CommunityOutReachCoordinatorTable />
      </div>
    </div>
  );
});

export default CommunityOutReachCoordinator;

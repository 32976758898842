import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import CareGiverStore from "../../../Store/CareGiverStore";
import { centerStyles, leftStyles } from "../../../Constants/Constants";
import GlobelStore from "../../../Store/GlobelStore";
import ActionPopUp from "./ActionPopUp";
import { FaCheck } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import SearchBox from "../../../components/Search/SearchBox";
import FormikController from "../../../components/FormikController/FormikController";
import ClientStore from "../../../Store/ClientStore";
import useSearchData from "../../../components/useSearchData";
import {
  statusClientCellRenderer,
  useOnGridReady,
} from "../../../Utils/AgGridUtils";
import { useNavigate } from "react-router-dom";
import Icon from "../../../components/Icons/Icon";
const ClientList = observer(() => {
  const navigate = useNavigate();
  const [handleSearchChange, handleActiveCheckBox] = useSearchData();
  const [onGridReady, onFirstDataRendered] = useOnGridReady(
    ClientStore,
    "clientAgGrid"
  );

  const fullNameCellRenderer = (params) => {
    const onFullNameClick = () => {
      const clientId = params.data.id;
      CareGiverStore.setIsCareGiverProfile(true);
      CareGiverStore.setIsUniqueCodeEntered(false);
      GlobelStore.setEmployeeData(params.data);
      navigate("/agency/Client_profile/" + clientId);
    };

    const fullName = `${params.data.fullName}`;

    return (
      <div onClick={onFullNameClick} class="ag-inner-cell" title={fullName}>
        {fullName}
      </div>
    );
  };

  const idCellRenderer = (params) => {
    const clientId = params.data.mra;
    const onFullNameClick = () => {
      const clientId = params.data.id;
      CareGiverStore.setIsCareGiverProfile(true);
      CareGiverStore.setIsUniqueCodeEntered(false);
      GlobelStore.setEmployeeData(params.data);

      navigate("/agency/Client_profile/" + clientId);
    };
    return (
      <div onClick={onFullNameClick} class="ag-inner-cell">
        {clientId}
      </div>
    );
  };
  const columnDefs = useMemo(
    () => [
      {
        field: "mra",
        headerName: "#MRA",
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: idCellRenderer,
      },
      {
        field: "fullName",
        headerName: "FullName",
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },

      {
        field: "status",
        headerName: "Status",
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: statusClientCellRenderer,
      },
      {
        field: "isAccountCreated",
        headerName: "Account Created",
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          return (
            <div
              className="ag-inner-cell"
              style={{
                fontSize: "16px",
                color: params.value == true ? "green" : "red",
              }}
            >
              {params.value ? <FaCheck /> : <MdClose />}
            </div>
          );
        },
      },
      {
        field: "isAccountEnable",
        headerName: "Account Status",
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          return (
            <div
              className="ag-inner-cell"
              style={{ color: params.value == true ? "green" : "red" }}
            >
              {params.value ? "Activate" : "Deactivate"}
            </div>
          );
        },
      },
      {
        headerName: "Action",
        sortable: false,
        headerClass: "center-header",
        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          const handleButtonClick = () => {
            CareGiverStore.setIsCareGiverAccountCreated(params.data.userId);
            GlobelStore.setEmployeeData(params.data);
            GlobelStore.setAccountAccessPopUpSelectedOn("client");
          };

          return (
            <div
              className="ag-inner-cell"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ActionPopUp
                type="careGiver"
                onConfirm={() => {}}
                clickHTML={
                  <div
                    className="boderRoundedButton boderRoundedButtonInline"
                    onClick={handleButtonClick}
                  >
                    <Icon
                      iconName="setting"
                      className="boderRoundedButtonIcon"
                    />
                    <span className="borderRoundedButtonText"> Manage</span>
                  </div>
                }
              />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className="actionButtonSearchMainContainer">
        <div className="searchMainCotainer">
          <div className="searchContainer">
            <SearchBox
              handleChange={(e) => {
                handleSearchChange(e, "Client");
              }}
            />
          </div>
          <div>
            <input
              id="careGiver_active"
              type="checkbox"
              onChange={(e) => {
                handleActiveCheckBox(e, "ACTIVE", "Client");
              }}
            />{" "}
            <span className="checkBoxTitle">Active</span>
          </div>
        </div>
      </div>

      <FormikController
        rowData={ClientStore.careGiverList}
        control="agGrid"
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  );
});

export default ClientList;

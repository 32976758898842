import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { AiOutlineClose } from "react-icons/ai"; // Import the X icon
import "../../../CSS/AccountAccess.css";
import { observer } from "mobx-react";
import FormikController from "../../../components/FormikController/FormikController";
import { useFormik } from "formik";
import { YupValidation } from "../../../Constants/ValidationConstants";
import * as Yup from "yup";
import AgencyStore from "../../../Store/AgencyStore";
import ClientStore from "../../../Store/ClientStore";
import { ClientURL } from "../../../Url/Url";
import axios from "axios";
import { useParams } from "react-router-dom";
import CareGiverStore from "../../../Store/CareGiverStore";
import { DialogActions } from "@mui/material";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const UpdateDigonisisPopUp = ({ onConfirm, clickHTML, type }) => {
  const { clientId } = useParams();

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [docterList, setDocterList] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      diagnosis: "",
    },

    validationSchema: Yup.object({
      diagnosis: YupValidation.diagnosis,
    }),
    onSubmit: (values) => {
      if (type == "clientDiagnosis") {
        if (status === "save") {
          saveClientProfileDiagnosis(values);
        } else {
          updateClientProfileDiagnosis(values);
        }
      }
    },
  });

  const saveClientProfileDiagnosis = (values) => {
    AgencyStore.setIsLoading(true);

    const params = {
      clientProfileId: clientId,
      diagnosis: values.diagnosis,
    };
    axios
      .post(ClientURL.SAVE_CLIENT_DIAGNOSIS, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          ClientStore.getClientProfileDiagnosis(clientId);
          formik.resetForm();
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Successfully added");
          setOpen(false);
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to save Diagnosis");
        }
        handleClose();
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to save Diagnosis");
      });
  };

  const updateClientProfileDiagnosis = (values) => {
    AgencyStore.setIsLoading(true);
    const params = {
      id: ClientStore.individualDiagnosisData.id,
      clientProfileId: clientId,
      diagnosis: values.diagnosis,
    };

    axios
      .post(ClientURL.UPDATE_CLIENT_DIAGNOSIS, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          ClientStore.getClientProfileDiagnosis(clientId);
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("success", "Successfully Updated");
        } else if (res?.data?.status == "failed") {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", res?.data?.message);
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to update Diagnosis");
        }
        handleClose();
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to update Diagnosis");
      });
  };

  const setProfileDataFormik = (data) => {
    const result = ClientStore.individualDiagnosisData;
    formik.setValues({
      diagnosis: result?.diagnosis || "",
    });
  };

  useEffect(() => {
    if (clientId) {
      setProfileDataFormik();
    }
  }, [clientId, ClientStore.individualDiagnosisData]);

  return (
    <div className="action-popup-container">
      <div className="action-trigger" onClick={handleClickOpen}>
        {clickHTML}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <div className="borderBottom">
          <DialogTitle
            id="draggable-dialog-title"
            className="DialogTitleContainer"
          >
            <div className="dialog-title-container">
              {ClientStore?.caseNotestatus === "update"
                ? "Update Digonisis"
                : "Add Digonisis"}
              <AiOutlineClose className="close-icon" onClick={handleClose} />
            </div>
          </DialogTitle>

          <div className="UCPUaction-popup-content">
            <div className="clientDoctorCaseNoteInputContainer">
              <div className="formInputContainer">
                <FormikController
                  name="diagnosis"
                  error
                  multiline
                  label="Diagnosis"
                  type="text"
                  minRows={2}
                  maxRows={4}
                  control="textfield"
                  {...formik.getFieldProps("diagnosis")}
                  touch={formik.touched.diagnosis}
                  errorname={formik.errors.diagnosis}
                  fullWidth="true"
                />
              </div>
            </div>
          </div>
        </div>

        <DialogActions className="DialogActionsContainer">
          <div>
            {ClientStore?.caseNotestatus === "update" ? (
              <FormikController
                name="Update Case Note"
                control="buttons"
                onClick={() => {
                  setStatus("update");

                  formik.handleSubmit();
                }}
                className="buttonSelectedGreen menuItemButton "
                fullWidth={false}
              />
            ) : (
              <FormikController
                name="Save Case Note"
                control="buttons"
                onClick={() => {
                  setStatus("save");
                  formik.handleSubmit();
                }}
                className="buttonSelectedGreen menuItemButton "
                fullWidth={false}
              />
            )}
          </div>
          <FormikController
            name="Cancel"
            control="buttons"
            onClick={handleClose}
            className="buttonUnselected fixedWidth"
            fullWidth="false"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(UpdateDigonisisPopUp);

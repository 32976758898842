export const employeeTypeOption = [
  { label: "PCA", value: "PCA" },
  { label: "RN", value: "RN" },
  { label: "CNA", value: "CNA" },
];

export const immigrationStatus = [
  { label: "Green Card", value: "Green Card" },
  { label: "US Citizen", value: "US Citizen" },
  { label: "Employment Authorization", value: "Employment Authorization" },
];

export const radioLabel = ["Male", "Female", "Others"];

export const nameTitle = [
  { label: "None", value: "" },

  { label: "Mr", value: "Mr." },
  { label: "Mrs", value: "Mrs." },
  { label: "Ms", value: "Ms." },
  { label: "Dr", value: "Dr." },
];
export const nameSuffix = [
  { label: "None", value: "" },

  { label: "Sr", value: "Sr" },
  { label: "Jr", value: "Jr" },
  { label: "i", value: "i" },
  { label: "ii", value: "ii" },
  { label: "iii", value: "iii" },
  { label: "iv", value: "iv" },
  { label: "v", value: "v" },
];
export const maritalStatusOption = [
  { label: "Single", value: "Single" },
  { label: "Married", value: "Married" },
  { label: "Widowed", value: "Widowed" },
  { label: "Separated", value: "Separated" },
  { label: "Divorced", value: "Divorced" },
];

export const status = [
  { label: "Active", value: "Active" },
  { label: "Not Working", value: "Not Working" },
];

export const clientStatusFilter = [
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "TERMINATE" },
];

export const clientStatus = [
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "TERMINATE" },
  {
    label: "Lead",
    value: "LEAD",
  },
  {
    label: "Initial Referral made to the county",
    value: "INITIAL_REFERAL_MADE_TO_THE_COUNTY",
  },
  {
    label: "Nurse Assessment Schedule",
    value: "NURSE_ASSESSMENT_SCHEDULE",
  },
  {
    label: "Nurse Assessment done",
    value: "NURSE_ASSESSMENT_DONE",
  },
  {
    label: "Support Planner Referral made",
    value: "SUPPORT_PLANNER_REFERAL_MADE",
  },
  {
    label: "Support Planner Visit",
    value: "SUPPORT_PLANNER_VISIT",
  },
  {
    label: "POS received",
    value: "POS_RECEIVED",
  },
  {
    label: "Pending MDH decision",
    value: "PENDING_MDH_DECISION",
  },
];

export const leadsStatus = [
  {
    label: "Lead",
    value: "LEAD",
  },
  {
    label: "Initial Referral made to the county",
    value: "INITIAL_REFERAL_MADE_TO_THE_COUNTY",
  },
  {
    label: "Nurse Assessment Schedule",
    value: "NURSE_ASSESSMENT_SCHEDULE",
  },
  {
    label: "Nurse Assessment done",
    value: "NURSE_ASSESSMENT_DONE",
  },
  {
    label: "Support Planner Referral made",
    value: "SUPPORT_PLANNER_REFERAL_MADE",
  },
  {
    label: "Support Planner Visit",
    value: "SUPPORT_PLANNER_VISIT",
  },
  {
    label: "POS received",
    value: "POS_RECEIVED",
  },
  {
    label: "Pending MDH decision",
    value: "PENDING_MDH_DECISION",
  },
  {
    label: "Move to Client",
    value: "ACTIVE",
  },
];

export const leadsStatusDropDown = [
  {
    label: "Select All",
    value: "Select All",
  },
  {
    label: "Lead",
    value: "LEAD",
  },
  {
    label: "Initial Referral made to the county",
    value: "INITIAL_REFERAL_MADE_TO_THE_COUNTY",
  },
  {
    label: "Nurse Assessment Schedule",
    value: "NURSE_ASSESSMENT_SCHEDULE",
  },
  {
    label: "Nurse Assessment done",
    value: "NURSE_ASSESSMENT_DONE",
  },
  {
    label: "Support Planner Referral made",
    value: "SUPPORT_PLANNER_REFERAL_MADE",
  },
  {
    label: "Support Planner Visit",
    value: "SUPPORT_PLANNER_VISIT",
  },
  {
    label: "POS received",
    value: "POS_RECEIVED",
  },
  {
    label: "Pending MDH decision",
    value: "PENDING_MDH_DECISION",
  },
  {
    label: "Move to Client",
    value: "ACTIVE",
  },
];

export const county = [
  { label: "Garrett County", value: "Garrett County" },
  { label: "Allegany County", value: "Allegany County" },
  { label: "Washington County", value: "Washington County" },
  { label: "Frederick County", value: "Frederick County" },
  { label: "Montgomery County", value: "Montgomery County" },
  { label: "Prince George’s County", value: "Prince George’s County" },
  { label: "Carroll County", value: "Carroll County" },
  { label: "Howard County", value: "Howard County" },
  { label: "Baltimore County", value: "Baltimore County" },
  { label: "Baltimore City", value: "Baltimore City" },
  { label: "Harford County", value: "Harford County" },
  { label: "Anne Arundel County", value: "Anne Arundel County" },
  { label: "Charles County", value: "Charles County" },
  { label: "St. Mary’s County", value: "St. Mary’s County" },
  { label: "Calvert County", value: "Calvert County" },
  { label: "Cecil County", value: "Cecil County" },
  { label: "Kent County", value: "Kent County" },
  { label: "Queen Anne’s County", value: "Queen Anne’s County" },
  { label: "Talbot County", value: "Talbot County" },
  { label: "Caroline County", value: "Caroline County" },
  { label: "Dorchester County", value: "Dorchester County" },
  { label: "Wicomico County", value: "Wicomico County" },
  { label: "Somerset County", value: "Somerset County" },
  { label: "Worcester County", value: "Worcester County" },
];

export const leftStyles = {
  color: "#444444",
  fontSize: "13px",
  textAlign: "left",
};

export const rightStyles = {
  color: "#444444",
  fontSize: "13px",
  textAlign: "right",
};

export const centerStyles = {
  color: "#444444",
  fontSize: "13px",
  textAlign: "center",
};

export const nurseVisitType = [
  { label: "Initial Visit", value: "INITIAL_ASSESSMENT" },
  { label: "Quaterly Visit", value: "THREE_MONTHS_VISIT" },
  { label: "Anually Visit", value: "ANNUAL" },
  { label: "Client Moves", value: "WHEN_THEY_MOVE" },
  { label: "Care giver Changed", value: "CARE_GIVER_CHANGE" },
];
export const posType = [
  { label: "Provisional", value: "PROVISIONAL" },
  { label: "Annual", value: "ANNUAL" },
  { label: "Initial", value: "INITIAL" },
  { label: "Revise", value: "REVISE" },
];

export const employeeName = {
  client: "Client",
  nurse: "Nurse",
  careGiver: "Personal Care Aid",
  adminEmployee: "Admin Employee",
  communityCordinatorLeftNav: "CO.Cordinator",
  communityCordinator: "Community Outreach Coordinator ",
  nurseSchedule: "Nurse Schedule",
  careGiverSchedule: "Personal Care Aid Schedule",
  changeLog: "Change Log",
  accountAccess: "Account/Access",
  dashBoard: "Dashboard",
  notification: "Notification",
  emailSms: "Send Email/SMS",
  leads: "Leads",
};

export const roleNames = {
  ChangeLog: employeeName.changeLog,
  AdminEmployee: employeeName.adminEmployee,
  CommunityCoordinator: employeeName.communityCordinator,
  AccountAndAccess: employeeName.accountAccess,
  CareGiver: employeeName.careGiver,
  Leads: employeeName.leads,
  Notifications: employeeName.notification,
  Nurse: employeeName.nurse,
  Client: employeeName.client,
  ManualNotification: employeeName.emailSms,
};
export const StatusTypeMap = [
  {
    displayName: "Lead",
    uniqueName: "LEAD",
  },
  {
    displayName: "Initial Referral made to the county",
    uniqueName: "INITIAL_REFERAL_MADE_TO_THE_COUNTY",
  },
  {
    displayName: "Nurse Assessment Schedule",
    uniqueName: "NURSE_ASSESSMENT_SCHEDULE",
  },
  {
    displayName: "Nurse Assessment done",
    uniqueName: "NURSE_ASSESSMENT_DONE",
  },
  {
    displayName: "Support Planner Referral made",
    uniqueName: "SUPPORT_PLANNER_REFERAL_MADE",
  },

  {
    displayName: "Support Planner Visit",
    uniqueName: "SUPPORT_PLANNER_VISIT",
  },
  {
    displayName: "POS received",
    uniqueName: "POS_RECEIVED",
  },
  {
    displayName: "Pending MDH decision",
    uniqueName: "PENDING_MDH_DECISION",
  },
  {
    displayName: "Active",
    uniqueName: "ACTIVE",
  },
  {
    displayName: "Inactive",
    uniqueName: "TERMINATE",
  },
];

export const posTypeMap = [
  {
    displayName: "POS",
    uniqueName: "pos",
  },
  {
    displayName: "Service Notification Form",
    uniqueName: "serviceNotificationForm",
  },
  {
    displayName: "Emergency Hour Isas Communication",
    uniqueName: "emgHourIsasCommunication",
  },

  {
    displayName: "Provisional",
    uniqueName: "PROVISIONAL",
  },
  {
    displayName: "Annual",
    uniqueName: "ANNUAL",
  },
  {
    displayName: "Initial",
    uniqueName: "INITIAL",
  },
  {
    displayName: "Revise",
    uniqueName: "REVISE",
  },
];

export const colorMap = {
  ACTIVE: "#3ac47d",
  LEAD: "#008FFB",
  TERMINATE: "#ff3c2a",
  INITIAL_REFERAL_MADE_TO_THE_COUNTY: "#0ebd82",
  NURSE_ASSESSMENT_SCHEDULE: "#FEB019",
  NURSE_ASSESSMENT_DONE: "#FF4560",
  SUPPORT_PLANNER_REFERAL_MADE: "#775DD0",
  SUPPORT_PLANNER_VISIT: "#3F51B5",
  POS_RECEIVED: "#546E7A",
  PENDING_MDH_DECISION: "#D4526E",
  Active: "#3ac47d",
  "Not Working": "#ff3c2a",
};

import React from "react";
import { Document, Page, View, Text, Image } from "@react-pdf/renderer";

import PdfFooter from "../PdfViewer/PdfFooter";
import { styles, pos } from "../PdfViewer/CommonUtilsPdf";
const CGPOSPdf = ({ dataList }) => {
  const SafetyTitle = () => (
    <View style={pos.title}>
      <View style={pos.heading}>
        <Text>Caregiver’s Plan of Service</Text>
      </View>

      <View style={pos.description}>
        <Text>
          Please indicate by checking YES or NO to the task to be provided.
        </Text>
      </View>
    </View>
  );
  const Details = () => (
    <View style={pos.detailContainer}>
      <View>
        <View style={pos.innerWrapper}>
          <Text>Client Name:</Text>
          <Text>{dataList.clientName}</Text>
        </View>
      </View>

      <View>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>CareGiver Name:</Text>
          <Text>{dataList.caregiverName}</Text>
        </View>
      </View>
    </View>
  );

  const TableHomeSafety = () => (
    <View style={pos.tableContainer}>
      <View style={styles.theader} fixed>
        <View style={pos.tableMidLength}>
          <Text style={styles.tableItem}>Task</Text>
        </View>

        <View style={pos.tableMidLength}>
          <Text style={styles.tableItem}>Yes</Text>
        </View>
        <View style={pos.tableMidLength}>
          <Text style={styles.tableItem}>No</Text>
        </View>
        <View style={pos.tableLargeLength}>
          <Text style={styles.tableItem}>Comments</Text>
        </View>
      </View>

      <View style={styles.category}>
        {dataList.taskList.map((item, index) => {
          return (
            <View style={styles.safetyTHeader} key={index}>
              <View style={pos.taskTBodyMidLength}>
                <Text>{item.task}</Text>
              </View>
              <View style={pos.taskTBodyMidLength}>
                <Text>{item.yesNo === true ? "X" : ""}</Text>
              </View>

              <View style={pos.taskTBodyMidLength}>
                <Text>{item.yesNo === false ? "X" : ""}</Text>
              </View>
              <View style={pos.taskTBodyLargeLength}>
                <Text>{item.comment}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );

  const SignatureData = () => (
    <View style={styles.finalContainer}>
      <View style={styles.detailWrapper}>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>RN Signature:</Text>
          {dataList.rnSignatureLink == null ? (
            ""
          ) : (
            <Image src={dataList.rnSignatureLink} style={styles.image} />
          )}
        </View>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>Date:</Text>
          <Text>{dataList.rnSignedDate}</Text>
        </View>
      </View>

      <View style={styles.detailWrapper}>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>CareGiver Signature:</Text>
          {dataList.cgSignatureLink == null ? (
            ""
          ) : (
            <Image src={dataList.cgSignatureLink} style={styles.image} />
          )}
        </View>
        <View style={styles.innerWrapper}>
          <Text style={styles.detailHeader}>Date:</Text>
          <Text>{dataList.caregiverSignedDate}</Text>
        </View>
      </View>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={pos.page}>
        <SafetyTitle />
        <Details />
        <TableHomeSafety />
        <SignatureData />

        <PdfFooter />
      </Page>
    </Document>
  );
};

export default CGPOSPdf;

import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import CareGiverStore from "../../../Store/CareGiverStore";
import { centerStyles, leftStyles } from "../../../Constants/Constants";
import GlobelStore from "../../../Store/GlobelStore";
import "../../../CSS/ManualNotification.css";

import SearchBox from "../../../components/Search/SearchBox";
import FormikController from "../../../components/FormikController/FormikController";
import ClientStore from "../../../Store/ClientStore";
import SendEmailSMS from "./SendEmailSMS";
import {
  CellRenderer,
  useOnGridReady,
  statusClientCellRenderer,
} from "../../../Utils/AgGridUtils";

import SpamError from "./SpamError";
import { FaCommentSms } from "react-icons/fa6";
import useSearchData from "../../../components/useSearchData";
import { useNavigate } from "react-router-dom";
import Icon from "../../../components/Icons/Icon";

const ClientList = observer(() => {
  const navigate = useNavigate();
  const [onGridReady, onFirstDataRendered, selectedIds] = useOnGridReady(
    ClientStore,
    "clientAgGrid",
    true
  );
  const [showError, setShowError] = useState(false);

  const fullNameCellRenderer = (params) => {
    const onFullNameClick = () => {
      const clientId = params.data.id;
      CareGiverStore.setIsCareGiverProfile(true);
      CareGiverStore.setIsUniqueCodeEntered(false);
      GlobelStore.setEmployeeData(params.data);

      navigate("/agency/Client_profile/" + clientId);
    };

    const fullName = `${params.data.fullName}`;

    return (
      <div onClick={onFullNameClick} class="ag-inner-cell" title={fullName}>
        {fullName}
      </div>
    );
  };

  const idCellRenderer = (params) => {
    const clientId = params.data.mra;
    const onFullNameClick = () => {
      const clientId = params.data.id;
      CareGiverStore.setIsCareGiverProfile(true);
      CareGiverStore.setIsUniqueCodeEntered(false);
      GlobelStore.setEmployeeData(params.data);

      navigate("/agency/Client_profile/" + clientId);
    };
    return (
      <div onClick={onFullNameClick} class="ag-inner-cell">
        {clientId}
      </div>
    );
  };
  const columnDefs = useMemo(
    () => [
      {
        field: "id",
        headerName: "",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 60,
        cellRenderer: () => {
          return null;
        },
      },
      {
        field: "mra",
        headerName: "#MRA",
        filter: true,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: idCellRenderer,
      },
      {
        field: "fullName",
        headerName: "FullName",
        filter: true,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },

      {
        field: "status",
        headerName: "Status",
        filter: true,
        headerClass: "center-header",

        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: statusClientCellRenderer,
      },
      {
        field: "phoneNo",
        headerName: "Phone No.",
        filter: true,

        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: CellRenderer,
      },
      {
        field: "email",
        headerName: "Email",
        filter: true,

        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: CellRenderer,
      },
    ],
    []
  );

  function isRowSelectable(rowNode) {
    if (rowNode.data && rowNode.data.status == "TERMINATE") {
      return false;
    }
    return true;
  }
  const getRowStyle = (params) => {
    if (params.data.status == "TERMINATE") {
      return { background: "lightgray" };
    }
    return null;
  };

  const handleSendEmailSMS = () => {
    if (selectedIds.length === 0) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  const [handleSearchChange, handleActiveCheckBox] = useSearchData();
  return (
    <div>
      <div className="actionButtonSearchMainContainer">
        <div className="searchMainCotainer">
          <div className="searchContainer">
            <SearchBox
              handleChange={(e) => {
                handleSearchChange(e, "Client");
              }}
            />
          </div>

          <div className="manualNotificationBtnContainer">
            <div className="CDCNunderlineClickBtnContainer">
              {showError && <SpamError />}
              {selectedIds.length === 0 ? (
                <div
                  className="boderRoundedButton"
                  onClick={() => {
                    handleSendEmailSMS();
                  }}
                >
                  <Icon iconName="sms" className="boderRoundedButtonIcon" />
                  <span className="boderRoundedButtonText">Send Email/SMS</span>
                </div>
              ) : (
                <SendEmailSMS
                  type="client"
                  selectedIds={selectedIds}
                  clickHTML={
                    <div
                      className="boderRoundedButton"
                      onClick={() => {
                        handleSendEmailSMS();
                      }}
                    >
                      <Icon iconName="sms" className="boderRoundedButtonIcon" />
                      <span className="boderRoundedButtonText">
                        Send Email/SMS
                      </span>
                    </div>
                  }
                />
              )}
            </div>

            <div>
              <input
                id="careGiver_active"
                type="checkbox"
                onChange={(e) => {
                  handleActiveCheckBox(e, "ACTIVE", "Client");
                }}
              />
              <span className="checkBoxTitle">Active</span>
            </div>
          </div>
        </div>
      </div>

      <FormikController
        rowData={ClientStore.careGiverList}
        control="agGrid"
        columnDefs={columnDefs}
        gridOptions={{
          isRowSelectable: isRowSelectable,
        }}
        onGridReady={onGridReady}
        getRowStyle={getRowStyle}
        rowSelection="multiple"
        rowMultiSelectWithClick={true}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  );
});

export default ClientList;

import React, { useRef, useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";
import CareGiverStore from "../../../../Store/CareGiverStore";
import { leftStyles, centerStyles } from "../../../../Constants/Constants";
import { useOnGridReady } from "../../../../Utils/AgGridUtils";
import FormikController from "../../../../components/FormikController/FormikController";
import { useNavigate } from "react-router-dom";
import DownloadClockInOutPopUp from "./DownloadClockInOutPopUp";
import AddClockInOut from "./AddClockInOut";
import GlobelStore from "../../../../Store/GlobelStore";
import ConfirmPopUp from "../../../../components/ConfirmPopUp/ConfirmPopUp";
import AgencyStore from "../../../../Store/AgencyStore";
import axios from "axios";
import { CareGiverURL } from "../../../../Url/Url";
import {
  formatDate,
  todayDate,
  twoWeeksBackDate,
} from "../../../../Utils/Utils";

const CareGiverClockInOutTable = observer(() => {
  const navigate = useNavigate();

  const [onGridReady] = useOnGridReady(CareGiverStore, "careGiverAgGrid");

  const fullNameCellRenderer = (params) => {
    const onFullNameClick = () => {
      const id = params.data.clientProfileId;
      CareGiverStore.setIsCareGiverProfile(true);
      navigate("/agency/Client_profile/" + id);
    };

    return (
      <div onClick={onFullNameClick} className="ag-inner-cell">
        {params.value}
      </div>
    );
  };

  const deleteClockInOutDetails = async (data) => {
    AgencyStore.setIsLoading(true);
    const params = {
      id: data?.id,
      clientId: data?.clientId,
      employeeId: data?.employeeId,
    };
    await axios
      .post(CareGiverURL.DELETE_EMP_CLOCKINOUT, params, {
        headers: { Authorization: localStorage.getItem("loginToken") },
      })
      .then((res) => {
        if (res?.data?.status === "success") {
          CareGiverStore.getEmpClockInOutDetails(
            data?.employeeId,
            formatDate(twoWeeksBackDate()),
            formatDate(todayDate())
          );
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert(
            "success",
            "Clock In/Out has been successfully deleted."
          );
        } else {
          AgencyStore.setIsLoading(false);
          AgencyStore.showAlert("error", "Failed to delete the Clock In/Out.");
        }
      })
      .catch((err) => {
        AgencyStore.setIsLoading(false);
        AgencyStore.showAlert("error", "Failed to delete the Clock In/Out.");
      });
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "SN",
        width: 100,
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: (params) => params.node.rowIndex + 1,
      },
      {
        field: "clientFullName",
        headerName: "Clinet Name",
        cellStyle: (params) => {
          return leftStyles;
        },
        cellRenderer: fullNameCellRenderer,
      },
      {
        field: "clockInDateTime",
        headerName: "Clock In Time",
        cellStyle: (params) => {
          return leftStyles;
        },
      },
      {
        field: "clockOutDateTime",
        headerName: "Clock Out Time",
        cellStyle: (params) => {
          return leftStyles;
        },
      },
      {
        headerName: "Action",
        sortable: false,
        headerClass: "center-header",

        cellStyle: (params) => {
          return centerStyles;
        },
        cellRenderer: (params) => {
          const handleButtonClick = () => {
            GlobelStore.setEmployeeData(params.data);
          };

          const handleSeleteButtonClick = () => {
            deleteClockInOutDetails(params.data);
          };

          return (
            <div
              className="ag-inner-cell"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <AddClockInOut
                type="update"
                clickHTML={
                  <div
                    className="manageClick"
                    onClick={() => handleButtonClick()}
                  >
                    Edit
                  </div>
                }
              />
              <ConfirmPopUp
                title="Delete"
                content="You are about to perform a critical action that cannot be undone. Please confirm your decision below."
                onConfirm={() => {
                  handleSeleteButtonClick();
                }}
                clickHTML={<div className="redDelete">Delete</div>}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  if (CareGiverStore.clockInOutAlldata?.resultSet.length > 0)
    CareGiverStore.setTableHeight(
      CareGiverStore.clockInOutAlldata?.resultSet.length
    );
  return (
    <FormikController
      control="agGrid"
      rowData={CareGiverStore.clockInOutAlldata?.resultSet}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
});

export default CareGiverClockInOutTable;

import { Font, pdf, StyleSheet, Text } from "@react-pdf/renderer";
export const openPdf = async (children) => {
  const blob = await pdf(children).toBlob();
  const pdfUrl = URL.createObjectURL(blob);
  window.open(pdfUrl, "_blank");
};

// Font.register({
//   family: "Open Sans",
//   font: [
//     {
//       src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
//       fontWeight: 600,
//     },
//     {
//       src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
//       fontWeight: 600,
//     },
//   ],
// });

export const styles = StyleSheet.create({
  page: {
    paddingTop: 40,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    paddingBottom: 40,
    flexDirection: "column",
  },
  pageNumber: {
    textAlign: "center",
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    color: "#2f2b3dc7",
    fontSize: "7px",
  },
  image: { width: 100, height: 20 },
  titleContainer: { marginTop: 24, textAlign: "center" },
  tableItem: { fontWeight: "bold", fontSize: "11px" },

  reportTitle: {
    fontSize: 16,
    textAlign: "center",
    color: "#333",
  },
  underline: { textDecoration: "underline", color: "#000" },

  theader: {
    flexDirection: "row",
    textAlign: "center",
  },
  theaders: {
    color: "#fff",
    flex: 1,
    backgroundColor: "#2f2b3dc7",
    fontWeight: 800,
    paddingTop: "10px",
    paddingBottom: "8px",
    marginTop: 10,
  },

  tbody: {
    flexWrap: "wrap",
    flex: 1,
    fontWeight: 800,
    paddingTop: "10px",
    fontSize: 10,
    paddingBottom: "8px",
    border: 0.2,
    letterSpacing: 0.2,
    backgroundColor: "#FFF",
  },

  //doctor list info table
  doctorTitle: { marginTop: 24, textAlign: "center" },

  doctorContainer: {
    borderTop: "1px solid #000",
    // borderLeft: "1px solid #000",
    marginTop: 20,
  },
  doctorInfo: {
    flexDirection: "row",
    borderBottom: "1px solid #000",
    fontSize: 11,
    alignItems: "center",
    borderLeft: "1px solid #000",
  },
  infoTitle: {
    flex: 1,
    padding: "11px 20px 10px 20px",
    borderRight: "1px solid #000",
  },
  infoItem: {
    flex: 2,
    padding: "11px 20px 10px 20px",
    borderRight: "1px solid #000",
  },
  //Styles for home safety assessment
  heading: {
    fontWeight: 800,
    fontSize: "10px",
    borderBottom: "1px solid #000",
    textTransform: "uppercase",
  },
  safetyTitle: {
    marginTop: 24,
  },
  detailContainer: {
    fontSize: "10px",
    marginTop: "10px",
  },
  detailWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  innerWrapper: {
    flexDirection: "row",
    gap: "5px",
  },
  detailHeader: {
    fontWeight: 800,
  },
  finalContainer: {
    flexDirection: "column",
    gap: "20px",
    marginBottom: 10,
    fontSize: 10,
  },
  recommendationTitle: {
    marginTop: 10,
    marginBottom: 10,
  },
  tableWrapper: {
    marginTop: 20,
    marginBottom: 10,
    // border: "1px solid #000",
  },
  tableTitle: {
    border: "0.2px solid #000",
    flex: 1,
    alignItems: "center",
    paddingTop: 2,
    marginTop: 10,
  },
  tableItemDescription: {
    border: "0.2px solid #000",
    flex: 8,
    alignItems: "center",
    paddingTop: 2,
    marginTop: 10,
  },
  category: {},
  categoryTitle: {
    border: "0.2px solid #000",
    fontSize: 10,
    textAlign: "center",
    paddingTop: 2,
  },
  safetyTHeader: {
    flexDirection: "row",
  },
  safetyTBody: {
    flex: 1,
    paddingTop: 1,
    fontSize: 10,
    border: "0.2px solid #000",
    letterSpacing: 0.2,
    alignItems: "center",
  },
  safetydescBody: {
    paddingTop: 1,
    flex: 8,
    fontSize: 10,
    border: "0.2px solid #000",
    letterSpacing: 0.2,
    paddingLeft: 2,
  },
});

export const pos = StyleSheet.create({
  page: {
    paddingTop: 40,
    paddingLeft: 40,
    paddingRight: 50,
    lineHeight: 1.5,
    paddingBottom: 50,
    flexDirection: "column",
  },
  title: {
    flexDirection: "column",
    gap: "15px",
    alignItems: "center",
    fontSize: "12px",
  },
  heading: {
    borderBottom: "1px solid #000",
    textTransform: "uppercase",
    textAlign: "center",
  },
  description: {
    fontSize: "12px",
  },
  detailContainer: {
    fontSize: "11px",
    marginTop: "10px",
    gap: "10px",
  },
  innerWrapper: {
    flexDirection: "row",
    gap: "5px",
  },
  tableContainer: {
    marginTop: "10px",
    marginBottom: 20,
    // borderLeft: "0.2px solid #000",
    borderTop: "0.2px solid #000",
    borderRight: "0.2px solid #000",
  },

  tableMidLength: {
    borderBottom: "0.2px solid #000",
    flex: 5,
    alignItems: "center",
    padding: 4,
    // marginTop: 10,
    borderLeft: "0.2px solid #000",
  },
  tableLargeLength: {
    borderBottom: "0.2px solid #000",
    flex: 8,
    alignItems: "center",
    padding: 4,
    // marginTop: 10,
    borderLeft: "0.2px solid #000",
  },

  tablesmallLength: {
    border: "0.2px solid #000",
    flex: 3,
    alignItems: "center",
    padding: 4,
    // marginTop: 10,
  },

  taskTBodyMidLength: {
    flex: 5,
    padding: 4,
    fontSize: 10,
    borderBottom: "0.2px solid #000",
    letterSpacing: 0.2,
    borderLeft: "0.2px solid #000",
  },

  taskTBodyLargeLength: {
    flex: 8,
    padding: 4,
    fontSize: 10,
    borderBottom: "0.2px solid #000",
    letterSpacing: 0.2,
    borderLeft: "0.2px solid #000",
  },
});

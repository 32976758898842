import React from "react";
import LogForm from "./LogForm";
import SearchBox from "../../../components/Search/SearchBox";
import ManualChangeLogTable from "./ManualChangeLogTable";
import { observer } from "mobx-react";

import useSearchData from "../../../components/useSearchData";
import Icon from "../../../components/Icons/Icon";

import TableFormPdf from "../PdfViewer/TableFormPdf";
import DownloadLink from "../PdfViewer/DownloadLink";
import { openPdf } from "../PdfViewer/CommonUtilsPdf";

const ChangeLog = observer((employeeCategory) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [handleSearchChange] = useSearchData();
  return (
    <div className="logMainContainer">
      <div className="searchButtonContainer">
        <div className="searchBox">
          <SearchBox
            handleChange={(e) => {
              handleSearchChange(e, employeeCategory.employeeCategory);
            }}
          />
        </div>
        <div className="changeLogBtnContainer">
          <div className="boderRoundedButton">
            {open && (
              <LogForm
                handleClose={handleClose}
                open={open}
                employeeCategory={employeeCategory}
              />
            )}
            <Icon iconName="circleAdd" className="boderRoundedButtonIcon" />
            <span className="boderRoundedButtonText" onClick={handleOpen}>
              Add Change Log
            </span>
          </div>

          <DownloadLink
            document={<TableFormPdf />}
            fileName="manualChangeLog.pdf"
          />
          <div
            className="boderRoundedButton"
            onClick={() => openPdf(<TableFormPdf />)}
          >
            <Icon iconName="view" className="boderRoundedButtonIconGreen" />
            <spam className="boderRoundedButtonText"> View</spam>
          </div>
        </div>
      </div>
      <ManualChangeLogTable
        employeeCategory={employeeCategory.employeeCategory}
      />
    </div>
  );
});

export default ChangeLog;
